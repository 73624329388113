import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import FetchFamilyTransactionDatatable from "./FetchFamilyTransactionDatatable.js";
import "../assets/css/customDatepicker.css";
import { Link, useParams } from "react-router-dom";
import { getOwedBalance } from "../../../services/invoiceService.js";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";
const TransactionByFamily = () => {
    const {
        fetchTransactionsByFamily,
    } = useUserDataContext();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [chargecat_name, set_chargecat_name] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [owedBalance, setOwedBalance] = useState("");
    const [fromDate, setFromDate] = useState(getFirstDateOfCurrentMonth());
    const [toDate, setToDate] = useState(getLastDateOfCurrentMonth());
    const param = useParams();

    console.log("from transaction by family------------", param);

    function getFirstDateOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        // Add leading zero if month is less than 10
        if (month < 10) {
            month = '0' + month;
        }
        return `${year}-${month}-01`;
    }

    function getLastDateOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let lastDay = new Date(year, month, 0).getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (lastDay < 10) {
            lastDay = '0' + lastDay;
        }
        return `${year}-${month}-${lastDay}`;
    }

    const handleDateChange = async (date) => {
        setSelectedDate(date);
        console.log("date is here---------", date);
        const response = await getOwedBalance(param.id, date);
        console.log("response from transaction by family---------", response)
        if (response?.success) {
            setOwedBalance(response.data.amount);
            fetchTransactionsByFamily(fromDate, toDate);
        } else {
            // Handle error or set a default value
            setOwedBalance(0);
        }
    };

    const owedBalanceHandler = async () => {
        const response = await getOwedBalance(param.id);
        // console.table("owed balance response: by table------", response)
        if (response?.success) {
            setOwedBalance(response.data.amount);
        } else {
            // Handle error or set a default value
            setOwedBalance(0);
        }
    }

    useEffect(() => {
        owedBalanceHandler();
        console.log("date------------", fromDate, toDate);
        fetchTransactionsByFamily(null, fromDate, toDate);
    }, [])


    return <div className="row">
        <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
            <div className="card flex-fill w-100">
                <div className="card-header w-100 pb-0">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <div class="remianing-amount-format">
                            <p>
                                <span>Balance remaining: <strong> {owedBalance} </strong> as of test </span>
                                <div className="row" style={{ paddingTop: "20px" }}>
                                    <div className="col-md-6">
                                        <label>From Date</label>
                                        <input value={fromDate} onChange={(e) => handleDateChange(e.target.value, toDate)} type="date" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label>To Date</label>
                                        <input value={toDate} onChange={(e) => handleDateChange(fromDate, e.target.value)} type="date" className="form-control" />
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card-body d-flex">
                    <div className="align-self-center w-100">
                        <FetchFamilyTransactionDatatable setIsEdit={setIsEdit} setModalIsOpen={setModalIsOpen} set_chargecat_name={set_chargecat_name} setSelectedId={setSelectedId} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default TransactionByFamily;