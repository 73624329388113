import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput";


export const AddEventModal = ({ isOpen, afterOpenModal, closeModal, style, contentLabel, eventDate, openQuickAddLessonModal, openNewEventModal, openNewNonTutoringEventModal  }) => (
    <ReactModal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={style}
            contentLabel={contentLabel}
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>{eventDate}</strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <div className="calendar-date-time">0 Scheduled Event(s)</div>
            <hr></hr>
            <div className="multi" onClick={openQuickAddLessonModal}>
              <i class="fa fa-calendar-check" aria-hidden="true"></i>
              <div>
                <strong>Quick-Add Lesson</strong>
                <p>
                  Create a new lesson with your default category, length, and
                  price.
                </p>
              </div>
            </div>
            <div className="multi" onClick={openNewEventModal}>
              <i class="fa fa-calendar-plus" aria-hidden="true"></i>
              <div>
                <strong>New Event</strong>
                <p>Create a new event with custom settings.</p>
              </div>
            </div>
            <div className="multi" onClick={openNewNonTutoringEventModal}>
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <div>
                <strong>New Non-Tutoring Event</strong>
                <p>Create a new event that doesn't require students.</p>
              </div>
            </div>
          </div>
        </ReactModal>
)