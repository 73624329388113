import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import FetchTransactionDatatable from "./FetchTransactionDatatable.js";
import "../users/assets/css/customDatepicker.css";
import { Link, useParams } from "react-router-dom";
import { getOwedBalance } from "../../services/invoiceService.js";

const Transaction = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [chargecat_name, set_chargecat_name] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [fromDate, setFromDate] = useState(getFirstDateOfCurrentMonth());
    const [toDate, setToDate] = useState(getLastDateOfCurrentMonth());
    const param = useParams();

    console.log("to date-------------", toDate, "fromDate-----------------", fromDate);

    function getFirstDateOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        // Add leading zero if month is less than 10
        if (month < 10) {
            month = '0' + month;
        }
        return `${year}-${month}-01`;
    }

    function getLastDateOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;  // JavaScript months are 0-based, so adding 1
        let lastDay = new Date(year, month, 0).getDate();  // Day '0' gives the last day of the previous month (which is current month)
        // Add leading zero to month and day if less than 10
        if (month < 10) {
            month = '0' + month;
        }
        if (lastDay < 10) {
            lastDay = '0' + lastDay;
        }
        return `${year}-${month}-${lastDay}`;
    }

    return <div className="row">
        <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
            <div className="card flex-fill w-100">
                <div className="card-header w-100">
                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <Link to={"/families-and-invoices/transaction-type/1/" + param.id}></Link>
                        <div className="row">
                            <div className="col-md-6">
                                <label>From Date</label>
                                <input value={fromDate} onChange={(e) => setFromDate(e.target.value)} type="date" className="form-control" />
                            </div>
                            <div className="col-md-6">
                                <label>To Date</label>
                                <input value={toDate} onChange={(e) => setToDate(e.target.value)} type="date" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body d-flex">
                    <div className="align-self-center w-100">
                        <FetchTransactionDatatable fromDate={fromDate} toDate={toDate} setIsEdit={setIsEdit} setModalIsOpen={setModalIsOpen} set_chargecat_name={set_chargecat_name} setSelectedId={setSelectedId} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Transaction;