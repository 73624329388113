import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AppContext from "./contextApi/userDataContext";
import { SettingsProvider } from "./contextApi/SettingsContext";
// import i18n (needs to be bundled ;))
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <SettingsProvider>
        <AppContext>
          <App />
        </AppContext>
      </SettingsProvider>
    </BrowserRouter>
  // </React.StrictMode>
);
