import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput";


export const CloneEventModal = ({isOpen,afterOpenModal,closeModal,style,contentLabel,event_name,start_date,start_time,end_time,set_start_date,set_start_time,set_end_time,cloneEventsHandler,formatDate,set_event_name }) =>(
    <ReactModal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={style}
          contentLabel={contentLabel}
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>Are You Sure To Clone ?</strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br></br>
            <form name="studentProfile">
              <div className="row d-flex">
                <hr></hr>
                <p>
                  <span className="formbold-form-label">Event Name : </span>
                  {event_name}
                </p>
                <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="tutor" className="formbold-form-label">
                    Title
                  </label>
                  <div>
                    <input
                      type="text"
                      name="event_name"
                      className="form-control"
                      value={event_name}
                      onChange={(e) => set_event_name(e.target.value)}
                    />
                  </div>
                </div>
              </div>
                <div className="formbold-input-flex">
                  <div>
                    <label htmlFor="start_date" className="formbold-form-label">
                    Date
                    </label>
                    <input
                      type="date"
                      name="start_date"
                      className="form-control"
                      value={formatDate(start_date)}
                      onChange={(e) => set_start_date(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <div>
                      <label htmlFor="date" className="formbold-form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        name="end_date"
                        className="form-control"
                        value={formatDate(end_date)}
                        onChange={(e) => set_end_date(e.target.value)}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="formbold-input-flex">
                  <div>
                    <label htmlFor="start_time" className="formbold-form-label">
                      Start Time
                    </label>
                    <br></br>

                    <input
                      type="time"
                      name="start_time"
                      className="form-control"
                      value={start_time}
                      onChange={(e) => set_start_time(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="end_time" className="formbold-form-label">
                      End Time
                    </label>
                    <br></br>

                    <input
                      type="time"
                      name="end_time"
                      className="form-control"
                      value={end_time}
                      onChange={(e) => set_end_time(e.target.value)}
                    />
                  </div>
                </div>
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <Link className="cancel" onClick={closeModal}>
                      Cancel
                    </Link>
                    <button
                      className="formbold-btn"
                      onClick={cloneEventsHandler}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>
) 