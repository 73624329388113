import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";
import students from "../assets/images/students.svg";
import Loader from "../../Loader.js";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import "./assets/css/style.css";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { API_URL } from "../../../utils/config.js";
import { toast } from "react-toastify";
import DeleteModel from "../../form/delete-model/DeleteModel.js";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FetchStudentDatatable = () => {
  const [val, setVal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { fetchStudentData, userId, studentData, token, getStudentStatus, statusList } = useUserDataContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [addNewDropdown, setAddNewDropdown] = useState(false);


  useEffect(() => {
    fetchStudentData();
    getStudentStatus();
  }, [userId]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickAddNew = (e) => {
    if (addNewDropdown == false) {
      setAddNewDropdown(true);
    } else {
      setAddNewDropdown(false);
    }
  };
  const handleMenuClick = (event) => {
    console.log('tetsttet : ', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (status) => {
    handleChangeStatus(status);
    handleMenuClose();
  };

  const onDeleteModelHandler = () => {
    setModalIsOpen(true);
  };

  const handleDelete = async (e) => {
    // Implement delete functionality here
    console.log("Deleting rows: ", selectedRows);
    const config = {
      method: "DELETE",
      url: `${API_URL}delete-students`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { "ids": selectedRows },
    };
    await axios(config)
      .then((response) => {
        setModalIsOpen(false);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchStudentData();
      })
      .catch((error) => {
        console.log(error);
        setModalIsOpen(false);
        if (error.response.data.success === false) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });

  };

  const handleChangeStatus = async (status) => {
    // Implement change status functionality here
    console.log("Changing status for rows: ", selectedRows);
    const config = {
      method: "PATCH",
      url: `${API_URL}students/update-status`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { "ids": selectedRows, "status": status },
    };
    await axios(config)
      .then((response) => {
        // setModalIsOpen(false);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSelectedRows([]);
        fetchStudentData();
      })
      .catch((error) => {
        console.log(error);
        setModalIsOpen(false);
        if (error.response.data.success === false) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "first_name",
      headerName: "First name",
      width: 150,
      renderCell: (params) => (
        <Link to={`/students/details/${params.row.id}`}>
          {params.row.first_name}
        </Link>
      ),
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email Id",
      width: 150,
    },
    {
      field: "status_label",
      headerName: "Status",
      width: 140,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    { field: "course_name", headerName: "Course Name", width: 200 },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "dob",
      headerName: "Birthday",
      width: 150,
    },
    {
      field: "parentfirstname",
      headerName: "Parent First Name",
      width: 150,
    },
    {
      field: "parentlatname",
      headerName: "Parent Last Name",
      width: 150,
    },
    {
      field: "parentemail",
      headerName: "Parent Email",
      width: 150,
    },
    {
      field: "parentmobile",
      headerName: "Parent Mobile",
      width: 150,
    },
    {
      field: "customer_number",
      headerName: "Customer Number",
      width: 150,
    },
    {
      field: "special_id_number",
      headerName: "Special Id Number",
      width: 150,
    },
    {
      field: "school",
      headerName: "School",
      width: 150,
    },
    {
      field: "skype",
      headerName: "Skype",
      width: 150,
    },
    {
      field: "studentsince",
      headerName: "Student Since",
      width: 150,
    },
    {
      field: "referrer",
      headerName: "Referrer",
      width: 150,
    },
    {
      field: "subjects",
      headerName: "Subjects",
      width: 150,
    },
    {
      field: "skill",
      headerName: "Skill Level",
      width: 150,
    },
    {
      field: "price",
      headerName: "Default Price",
      width: 150,
    },
    {
      field: "lessoncat",
      headerName: "Lesson Category",
      width: 150,
    },
    {
      field: "lessonlen",
      headerName: "Default Lesson Length",
      width: 150,
    },
    {
      field: "makeup_credits_value",
      headerName: "Total Makeup Credit",
      width: 150,
    },
    {
      field: "studentFamily",
      headerName: "Student Family",
      width: 150,
    },
    {
      field: "studentType",
      headerName: "Student Type",
      width: 150,
    },
  ];

  useEffect(() => {
    setVal(true);
    console.log(studentData);
  }, [studentData]);

  if (val) {
    var rows = studentData;
  }

  return (
    <div>
      <>
        {rows && studentData.length > 0 ? (
          <>
            <DeleteModel
              isLoading={isDeleteLoading}
              setIsLoading={setIsDeleteLoading}
              modalIsOpen={modalIsOpen}
              id={'1'}
              setIsOpen={setModalIsOpen}
              onOk={handleDelete}
            />
            <div className="py-3">
              <div className="bulk-actions">
                <div className="pr-3">
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => onDeleteModelHandler()}
                    disabled={selectedRows.length === 0}
                    startIcon={<DeleteIcon color="disabled" />} //primary

                  > Delete
                  </Button>
                </div>
                <div className="ml-3">
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleMenuClick}
                    disabled={selectedRows.length === 0}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Change Status
                  </Button>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {statusList.map((status) => {
                    return (
                      <MenuItem key={status.id} onClick={() => handleMenuItemClick(status.id)}>
                        <span className="status-design" style={{ color: status.status_color, backgroundColor: status.bg_color }}>
                          {status.status_title}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div className="chart chart-xs">
                <Box
                  sx={{
                    height: 400,
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      fontSize: "15px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "700",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                          referrer: false,
                          skill: false,
                          gender: false,
                          dob: false,
                          studentsince: false,
                          price: false,
                          subjects: false,
                          school: false,
                          skype: false,
                          lessoncat: false,
                          lessonlen: false,
                          studentFamily: false,
                          studentType: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    selectionModel={selectedRows}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRows(newRowSelectionModel);
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </Box>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="py-3">
              <div className="chart chart-xs">
                <img src={students} alt="No students" />
              </div>
            </div>
            <h4>
              <strong>You don't have any students yet</strong>
            </h4>
            <p style={{ textAlign: "center" }}>
              Add your students so you can take their attendance, and more.
            </p>
            {/* <div className="addnewstudent addnew">
              <i className="fa fa-plus" aria-hidden="true"></i>
              <a className="btn dropdown-toggle" href="#" role="button">
                Add Newssssss
              </a>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuLink"
              ></div>
            </div> */}
            <div className="addnewstudent addnew students" onClick={handleClickAddNew}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <a className="btn dropdown-toggle" role="button">
                Add New
              </a>

              {addNewDropdown && (
                <>
                  <div className="dropdown-menu addNewDropdown">
                    <Link className="dropdown-item" to={"/students/add"}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      New Student
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to={"/students/import"}>
                      <i className="fa fa-cloud-download" aria-hidden="true"></i>
                      Import Students
                    </Link>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default FetchStudentDatatable;