import React from "react";

const BusinessProfileView = ({ logo, initial, businessName, setIsOpen, regionLabel, timezoneLabel, currencyLabel }) => {
  return (
    <div className="col-xl-4 col-xxl-4">
      <div className="card">
        <div className="card-body">
          <div className="initials">
            <div className="image-user">
            {logo ? (
                <img src={logo} alt="" />
            ) : (
              <h2>{initial && initial.toLocaleUpperCase()}</h2>
            )}
            </div>
          </div>
          <div className="edit-user" onClick={(e) => setIsOpen("profile")}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </div>
          <div className="title-user">
            {businessName}
          </div>
          <div className="email-user">
            <i className="fa fa-globe" aria-hidden="true"></i> {regionLabel}
          </div>
          <div className="location-user">
            <i className="fa fa-clock" aria-hidden="true"></i> {timezoneLabel}
          </div>
          <div className="location-user">
            <i className="fa fa-usd" aria-hidden="true"></i> {currencyLabel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfileView;
