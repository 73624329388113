import axios from "axios";
import { API_URL } from "../utils/config";


export const verifyToken = async (data) => {
  const config = {
    method: "GET",
    url: `${API_URL}`,
  };
  return await axios(config).then((response) => {
    return true;
  })
  .catch((error) => {
    return false;
  });
};