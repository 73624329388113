import React from "react";
import { useTranslation } from "react-i18next";

const SMTPSettings = ({ handleChange, formData, formSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <strong>{t("SMTP Settings")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("SMTP Credentials")}</h3>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="mail_driver" className="formbold-form-label">
                    {t("Mail Driver")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="text"
                      name="mail_driver"
                      className="form-control"
                      placeholder={t("Mail Driver")}
                      onChange={handleChange}
                      value={formData.mail_driver}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="mail_host" className="formbold-form-label">
                    {t("Mail Host")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="text"
                      name="mail_host"
                      className="form-control"
                      placeholder={t("Mail Host")}
                      onChange={handleChange}
                      value={formData.mail_host}
                    />
                  </div>
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="mail_port" className="formbold-form-label">
                    {t("Mail Port")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="text"
                      name="mail_port"
                      className="form-control"
                      placeholder={t("Mail Port")}
                      onChange={handleChange}
                      value={formData.mail_port}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="mail_encryption" className="formbold-form-label">
                    {t("Mail Encryption")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="text"
                      name="mail_encryption"
                      className="form-control"
                      placeholder={t("Mail Encryption")}
                      onChange={handleChange}
                      value={formData.mail_encryption}
                    />
                  </div>
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="mail_username" className="formbold-form-label">
                    {t("Mail Username")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="text"
                      name="mail_username"
                      className="form-control"
                      placeholder={t("Mail Username")}
                      onChange={handleChange}
                      value={formData.mail_username}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="mail_password" className="formbold-form-label">
                    {t("Mail Password")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="text"
                      name="mail_password"
                      className="form-control"
                      placeholder={t("Mail Password")}
                      onChange={handleChange}
                      value={formData.mail_password}
                    />
                  </div>
                </div>
              </div>
              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <button className="formbold-btn" onClick={formSubmit}>
                    {t("Save Changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMTPSettings;
