import React, { useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { useUserDataContext } from '../contextApi/userDataContext.js';
import { API_URL } from '../utils/config.js';
import useGlobalSettings from '../hooks/useGlobalSettings.js';


export const CKEditorComponent = () => {
  const [message, setMessage] = useState('');
  const editorRef = useRef(null);
  const { emailData, setEmailData, token } = useUserDataContext();
  const { getSetting } = useGlobalSettings();

  // implement to image uplaod into this editor
  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
  
    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${API_URL}uploadmedia`, true);

            // Retrieve the bearer token from sessionStorage
            const token = getSetting("authToken");

            // Add the Authorization header with the Bearer token
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);

            const formData = new FormData();
            formData.append('file', file);
  
            xhr.onload = () => {
              if (xhr.status < 200 || xhr.status >= 300) {
                return reject('Image upload failed');
              }
              const response = JSON.parse(xhr.responseText);
              resolve({ default: response.location });
            };
  
            xhr.onerror = () => reject('Upload failed');
            xhr.send(formData);
          })
      );
    }
  
    abort() {
      // Handle abort
    }
  }
  
  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  // save the data functionality
  console.log("email data from CK5 editor  -------------->", emailData);
  
  const handleSaveData = async () => {
    const validateconfig = {
      method: "PATCH",
      url: `${API_URL}et/${emailData.id}/save`,
      data: JSON.stringify({
        user_id: emailData.user_id,
        template_id: emailData.id,
        template_title: emailData.template_title,
        template_content: editorRef.current.getData(),
      }),
    };
    await axios(validateconfig)
      .then((response) => {
        setMessage(response.data.message);
        setTimeout(() => setMessage(''), 2000);
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name from handle change------------>", name);
  
    // Use the callback form of setState to ensure the most recent state is used
    setEmailData((prevState) => ({
      ...prevState,       // Spread the previous state to retain other values
      [name]: value       // Update the specific field by its name
    }));
  };
   
  return (
    <div className="col-xl-12 col-xxl-12">
      <div className="input-title">
        <h6>Template Title</h6>
        <input
          type="text"
          className="form-control"
          placeholder="Email Title"
          name="template_title"
          value={emailData.template_title}
          onChange={handleChange}
        />
      </div>
      <div className="editor">
        <h6>Template Body</h6>
        <CKEditor
          editor={ClassicEditor}
          data={emailData.template_content || ''}
          onReady={(editor) => {
            editorRef.current = editor;
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEmailData((prevState) => ({
              ...prevState,       // Spread the previous state to retain other values
              template_content: data      // Update the specific field by its name
            }));
          }} 
          config={{
            extraPlugins: [MyCustomUploadAdapterPlugin],
            toolbar: [
              'heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 
              'blockQuote', 'imageUpload', 'mediaEmbed', 'insertTable', 'undo', 'redo',
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
            },
            table: {
              contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
            },
          }}
        />
      </div>
      {message && (
        <span style={{ color: 'green', textAlign: 'center', paddingTop: '10px' }}>
          {message}
        </span>
      )}
      <div className="formbold-form-btn-wrapper justify-content-end">
        <div className="btn-end sub-btn">
          <button className="formbold-btn" onClick={handleSaveData}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
  

}