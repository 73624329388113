import axios from "axios";
import { API_URL } from "../utils/config";

export const getPayrollOverideTypes = async () => {
  const config = {
    method: "GET",
    url: `${API_URL}potypes`
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      console.log(error);
    });
};
export const createCategories = async (data) => {
  const config = {
    method: "POST",
    url: `${API_URL}create-eventcat`,
    data: data,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      return error;
    });
};
export const updateCategories = async (data, id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}update-eventcat/`+id,
    data: data,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      return error;
    });
};
export const deleteCategories = async (id) => {
  const config = {
    method: "DELETE",
    url: `${API_URL}delete-eventcat/`+id,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      return error;
    });
};
export const getCategories = async () => {
  const config = {
    method: "GET",
    url: `${API_URL}eventcats`,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      console.log(error);
    });
};
export const getCategoriesDetails = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}eventcat/`+id,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      console.log(error);
    });
};
export const createChargeCategories = async (data) => {
  const config = {
    method: "POST",
    url: `${API_URL}create-chargecat`,
    data: data,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      return error;
    });
};
export const updateChargeCategories = async (data, id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}update-chargecat/`+id,
    data: data,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      return error;
    });
};
export const deleteChargeCategories = async (id) => {
  const config = {
    method: "DELETE",
    url: `${API_URL}delete-chargecat/`+id,
  };
  return await axios(config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      return error;
    });
};
