// GeneralSettings.js
import React from "react";

/**
 * GeneralSettings component displays general settings related to attendance preferences and email notifications.
 * @param {Object} props - Component props.
 * @param {Object} props.tenantData - Object containing tenant settings data.
 * @param {boolean} props.attendDisabled - Flag indicating if attendance settings are disabled.
 * @param {boolean} props.emailDisabled - Flag indicating if email notification settings are disabled.
 * @param {Function} props.handleChange - Function to handle input changes.
 * @param {Function} props.handleAttendEdit - Function to handle editing attendance preferences.
 * @param {boolean} props.attendFlag - Flag indicating if attendance form is being edited.
 * @param {Function} props.handleCancelAttendFlag - Function to cancel editing attendance.
 * @param {Function} props.formSubmit - Function to submit form changes.
 * @param {Function} props.handleEmailEdit - Function to handle editing email notification preferences.
 */
const GeneralSettings = ({
  tenantData,
  attendDisabled,
  emailDisabled,
  handleChange,
  handleAttendEdit,
  attendFlag,
  handleCancelAttendFlag,
  formSubmit,
  handleEmailEdit,
}) => (
  <div className="card">
    {/* Accordion for General section */}
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item">
        {/* Accordion header */}
        <h2 className="accordion-header" id="flush-headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            <strong>General</strong>
          </button>
        </h2>
        {/* Accordion collapse content */}
        <div
          id="flush-collapseOne"
          className="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div className="accordion-body">
            {/* Attendance Preferences section */}
            <div className="student-properties-edit sec-acc">
              <h3>Attendance Preferences</h3>
              {/* Edit icon for attendance preferences */}
              <div className="student-edit-user" onClick={handleAttendEdit}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </div>
            </div>

            {/* Checkbox for overdue attendance */}
            <div className={!attendDisabled ? "" : "disabled-form"}>
              <div className="formbold-input-flex">
                <div>
                  <label
                    htmlFor="overdue_attendence"
                    className="formbold-form-label"
                  >
                    Overdue Attendance
                  </label>
                  <br />
                  <label className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="checkbox"
                      name="overdue_attendence"
                      value="true"
                      disabled={attendDisabled}
                      onChange={handleChange}
                      checked={
                        tenantData?.overdue_attendence !== null ? true : false
                      }
                    />
                    Show overdue attendance on homepage
                  </label>
                </div>
              </div>

              {/* Radio buttons for default notes view */}
              <div className="formbold-input-flex diff">
                <div>
                  <div>
                    <label
                      htmlFor="default_notes_view"
                      className="formbold-form-label"
                    >
                      Default Notes View
                    </label>
                  </div>
                  <div className="input-radio">
                  <label>
                    <input
                      type="radio"
                      value="Student"
                      name="default_notes_view"
                      disabled={attendDisabled}
                      onChange={handleChange}
                      checked={
                        tenantData?.default_notes_view === "Student" ? true : false
                      }
                    />
                    Student
                    </label>
                    <label>
                    <input
                      type="radio"
                      value="Parent"
                      name="default_notes_view"
                      disabled={attendDisabled}
                      onChange={handleChange}
                      checked={
                        tenantData?.default_notes_view === "Parent" ? true : false
                      }
                    />
                    Parent
                    </label>
                    <label>
                    <input
                      type="radio"
                      value="Private"
                      name="default_notes_view"
                      disabled={attendDisabled}
                      onChange={handleChange}
                      checked={
                        tenantData?.default_notes_view === "Private" ? true : false
                      }
                    />
                    Private
                    </label>
                  </div>
                </div>
              </div>

              {/* Checkbox for automatically copying lesson notes */}
              <div className="formbold-input-flex diff mb-0">
                <div>
                  <label
                    htmlFor="automatically_copy_lesson"
                    className="formbold-form-label"
                  >
                    Lesson Notes
                  </label>
                  <br />
                  <label className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="checkbox"
                      name="automatically_copy_lesson"
                      value="true"
                      disabled={attendDisabled}
                      onChange={handleChange}
                      checked={
                        tenantData?.automatically_copy_lesson !== null
                          ? true
                          : false
                      }
                    />
                    Automatically copy lesson notes when I take attendance
                  </label>
                </div>
              </div>

              {/* Radio buttons for copying from recent event */}
              <div className="formbold-input-flex diff">
                <div className="input-radio" style={{ fontSize: "15px" }}>
                <label>
                  <input
                    type="radio"
                    value="Copy from most recent event"
                    name="copy_recent_event"
                    disabled={attendDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.copy_recent_event ===
                      "Copy from most recent event"
                        ? true
                        : false
                    }
                  />
                  Copy from most recent event
                  </label>
                  <label>
                  <input
                    type="radio"
                    value="Copy from same category only"
                    name="copy_recent_event"
                    disabled={attendDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.copy_recent_event ===
                      "Copy from same category only"
                        ? true
                        : false
                    }
                  />
                  Copy from same category only
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Email Notification Preferences section */}
          <div className="accordion-body pt-0">
            <div className="student-properties-edit sec-acc">
              <h3>Email Notification Preferences</h3>
              {/* Edit icon for email notification preferences */}
              <div className="student-edit-user" onClick={handleEmailEdit}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </div>
            </div>

            {/* Description for email notification preferences */}
            <small className="small">
              Select what you'd like to be notified about via email and how often
            </small>

            {/* Email notification options */}
            <div className={emailDisabled ? "disabled-form" : ""}>
              <div className="make-pad pb-3">
                <label className="input-check">
                  <input
                    type="checkbox"
                    name="student_register_lesson"
                    value="When any student registers for a lesson/event through the Student Portal"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.student_register_lesson !== null ? true : false
                    }
                  />
                  When any student registers for a lesson/event through the Student Portal
                </label>
                <label className="input-check">
                  <input
                    type="checkbox"
                    name="student_cancel_lesson"
                    value="When any student cancels a lesson/event through the Student Portal"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.student_cancel_lesson !== null ? true : false
                    }
                  />
                  When any student cancels a lesson/event through the Student Portal
                </label>
                <label className="input-check">
                  <input
                    type="checkbox"
                    name="parent_student_signup"
                    value="When any parent or student completes the sign-up form"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.parent_student_signup !== null ? true : false
                    }
                  />
                  When any parent or student completes the sign-up form
                </label>
                <label className="input-check">
                  <input
                    type="checkbox"
                    name="parent_student_disable_email_reminder"
                    value="When any parent or student disables email reminders"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.parent_student_disable_email_reminder !== null
                        ? true
                        : false
                    }
                  />
                  When any parent or student disables email reminders
                </label>
                <label className="input-check">
                  <input
                    type="checkbox"
                    name="allow_student_email_studylog"
                    value="Allow students to email me from the Study Log"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.allow_student_email_studylog !== null ? true : false
                    }
                  />
                  Allow students to email me from the Study Log
                </label>
              </div>

              {/* Email daily agenda settings */}
              <div className="email-pad-top">
                <label
                    className="formbold-form-label"
                  >
                    Email Daily Agenda
                </label>
                <div className="input-radio align-items-start">
                <label className="d-flex align-items-start">
                  <input
                    type="radio"
                    value="Day Before"
                    name="daily_agenda"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.daily_agenda === "Day Before" ? true : false
                    }
                    className="m-1"
                  />
                  <span>
                    Day Before
                    <br />
                    Between 16:00 and 23:00 </span>
                  </label>
                  <label className="d-flex align-items-start">
                  <input
                    type="radio"
                    value="Same Day"
                    name="daily_agenda"
                    disabled={emailDisabled}
                    onChange={handleChange}
                    checked={
                      tenantData?.daily_agenda === "Same Day" ? true : false
                    }
                    className="m-1"
                  />
                  Same Day
                  <br />
                  Between 0:00 and 15:00
                  </label>
                  <label className="d-flex align-items-start">
                    <input
                      type="radio"
                      value="Don't Email"
                      name="daily_agenda"
                      disabled={emailDisabled}
                      onChange={handleChange}
                      checked={
                        tenantData?.daily_agenda === "Don't Email" ? true : false
                      }
                      className="m-1"
                    />
                    Don't Email
                  </label>
                </div>
              </div>
            </div>

            {/* Save and Cancel buttons */}
            {attendFlag && (
              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <a className="cancel" onClick={handleCancelAttendFlag}>
                    Cancel
                  </a>
                  <button className="formbold-btn" onClick={formSubmit}>
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GeneralSettings;
