import React, { useContext, useState } from "react";
import "../users/assets/css/style.css";
import { Link, useLocation } from "react-router-dom";
import LanguageOption from "../LanguageOption";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useGlobalSettings from "../../hooks/useGlobalSettings.js";


const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { t } = useTranslation();
  const { getSetting } = useGlobalSettings();
  const role = String(getSetting("userRole"));
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <nav id="sidebar" className="sidebar js-sidebar">
      <div className="sidebar-content js-simplebar">
        <Link className="sidebar-brand" to="/dashboard">
          <span className="align-middle">{t("tutorpad")}</span>
        </Link>

        {/* Business Role sidebar */}
        {role === `${process.env.REACT_APP_BUSINESS_ROLE}` &&
          <ul className="sidebar-nav">
            <li
              className={`sidebar-item ${splitLocation[1] === "dashboard" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.dashboard")}</span>
              </Link>
            </li>

            {role === `${process.env.REACT_APP_BUSINESS_ROLE}` &&
              <li
                className={`sidebar-item ${splitLocation[1] === "tutors" ? "active" : ""
                  }`}
              >
                <Link className="sidebar-link" to="/tutors">
                  <i className="fa fa-id-badge" aria-hidden="true"></i>
                  <span className="align-middle">{t("sidebar.tutorsAndStaff")}</span>
                </Link>
              </li>
            }

            {role != `${process.env.REACT_APP_STUDENT_ROLE}` &&
              <li
                className={`sidebar-item ${splitLocation[1] === "students" ? "active" : ""
                  }`}
              >
                <Link className="sidebar-link" to="/students">
                  <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                  <span className="align-middle">{t("sidebar.students")}</span>
                </Link>
              </li>
            }

            <li
              className={`sidebar-item ${splitLocation[1] === "courses" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/course">
                <i className="fa fa-book" aria-hidden="true"></i>
                <span className="align-middle">{t("Courses")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "calendar" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/calendar">
                <i className="fa fa-calendar" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.calendar")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "families-and-invoices" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/families-and-invoices">
                <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.familiesAndInvoices")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "lending-library" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/lending-library">
                <i className="fa fa-book" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.lendingLibrary")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "online-resources" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/online-resources">
                <i className="fa fa-cloud-download" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.onlineResources")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "expenses-and-other-revenues" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-calculator" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.expensesAndRevenues")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "mileage" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.mileage")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "website" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-window-restore" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.website")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "news-blog-posts" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-bullhorn" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.newsAndPosts")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "business-reports" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-file-text" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.businessReports")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "email-templates" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/email-templates">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.emailTemplates")}</span>
              </Link>
            </li>
          </ul>
        }

        {/* REACT_APP_SINGLE_BUSINESS_ROLE */}
        {role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` &&
          <ul className="sidebar-nav">
            <li
              className={`sidebar-item ${splitLocation[1] === "dashboard" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.dashboard")}</span>
              </Link>
            </li>

            {role === `${process.env.REACT_APP_BUSINESS_ROLE}` &&
              <li
                className={`sidebar-item ${splitLocation[1] === "tutors" ? "active" : ""
                  }`}
              >
                <Link className="sidebar-link" to="/tutors">
                  <i className="fa fa-id-badge" aria-hidden="true"></i>
                  <span className="align-middle">{t("sidebar.tutorsAndStaff")}</span>
                </Link>
              </li>
            }

            {role != `${process.env.REACT_APP_STUDENT_ROLE}` &&
              <li
                className={`sidebar-item ${splitLocation[1] === "students" ? "active" : ""
                  }`}
              >
                <Link className="sidebar-link" to="/students">
                  <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                  <span className="align-middle">{t("sidebar.students")}</span>
                </Link>
              </li>
            }

            <li
              className={`sidebar-item ${splitLocation[1] === "calendar" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/calendar">
                <i className="fa fa-calendar" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.calendar")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "lending-library" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/lending-library">
                <i className="fa fa-book" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.lendingLibrary")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "online-resources" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/online-resources">
                <i className="fa fa-cloud-download" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.onlineResources")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "families-and-invoices" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/families-and-invoices">
                <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.familiesAndInvoices")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "expenses-and-other-revenues" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-calculator" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.expensesAndRevenues")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "mileage" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.mileage")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "website" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-window-restore" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.website")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "news-blog-posts" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-bullhorn" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.newsAndPosts")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "business-reports" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-file-text" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.businessReports")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "email-templates" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/email-templates">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.emailTemplates")}</span>
              </Link>
            </li>
          </ul>
        }

        {/* Tutor Role sidebar */}
        {role === `${process.env.REACT_APP_TUTOR_ROLE}` &&
          <ul className="sidebar-nav">
            <li
              className={`sidebar-item ${splitLocation[1] === "dashboard" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.dashboard")}</span>
              </Link>
            </li>

            {role === `${process.env.REACT_APP_BUSINESS_ROLE}` &&
              <li
                className={`sidebar-item ${splitLocation[1] === "tutors" ? "active" : ""
                  }`}
              >
                <Link className="sidebar-link" to="/tutors">
                  <i className="fa fa-id-badge" aria-hidden="true"></i>
                  <span className="align-middle">{t("sidebar.tutorsAndStaff")}</span>
                </Link>
              </li>
            }

            {role != `${process.env.REACT_APP_STUDENT_ROLE}` &&
              <li
                className={`sidebar-item ${splitLocation[1] === "students" ? "active" : ""
                  }`}
              >
                <Link className="sidebar-link" to="/students">
                  <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                  <span className="align-middle">{t("sidebar.students")}</span>
                </Link>
              </li>
            }

            <li
              className={`sidebar-item ${splitLocation[1] === "calendar" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/calendar">
                <i className="fa fa-calendar" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.calendar")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "lending-library" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/lending-library">
                <i className="fa fa-book" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.lendingLibrary")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "online-resources" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/online-resources">
                <i className="fa fa-cloud-download" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.onlineResources")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "families-and-invoices" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/families-and-invoices">
                <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.familiesAndInvoices")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "expenses-and-other-revenues" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-calculator" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.expensesAndRevenues")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "mileage" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.mileage")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "website" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-window-restore" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.website")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "news-blog-posts" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-bullhorn" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.newsAndPosts")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "business-reports" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="#">
                <i className="fa fa-file-text" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.businessReports")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "email-templates" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/email-templates">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.emailTemplates")}</span>
              </Link>
            </li>
          </ul>
        }

        {/* Student Role sidebar */}
        {role === `${process.env.REACT_APP_STUDENT_ROLE}` &&
          <ul className="sidebar-nav">
            <li
              className={`sidebar-item ${splitLocation[1] === "dashboard" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.dashboard")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "calendar" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/calendar">
                <i className="fa fa-calendar" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.calendar")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "borrowing-library" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/student/borrowing-library">
                <i className="fa fa-book" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.borrowingLibrary")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "online-resources" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/online-resources">
                <i className="fa fa-cloud-download" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.onlineResources")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "study-logs" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/student/study-logs">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.studyLogs")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "account-invoices" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/student/account-invoices">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.accountAndInvoices")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "attendance-notes" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/student/attendance-notes">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.attendanceAndNotes")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "news" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/student/news">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.news")}</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${splitLocation[1] === "message-history" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/student/message-history">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.messageHistory")}</span>
              </Link>
            </li>
          </ul>
        }

        {/* Parent Role sidebar */}
        {role === `${process.env.REACT_APP_PARENT_ROLE}` &&
          <ul className="sidebar-nav">
            <li
              className={`sidebar-item ${splitLocation[1] === "dashboard" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.dashboard")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "calendar" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/calendar">
                <i className="fa fa-calendar" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.calendar")}</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${splitLocation[1] === "students" ? "active" : ""
                }`}
            >
              <Link className="sidebar-link" to="/students">
                <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.students")}</span>
              </Link>
            </li>

            <li>
              <Link className="sidebar-link" to="/parent/invoice-details/">
                <i className="fa fa-text-width" aria-hidden="true"></i>
                <span className="align-middle">{t("sidebar.familiesAndInvoices")}</span>
              </Link>
            </li>


          </ul>
        }
      </div>
    </nav >
  );
};

export default Sidebar;
