import loader from "../assets/images/loader.gif";
import "./Loader.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import useGlobalSettings from "../hooks/useGlobalSettings";

const Loader = () => {

  const navigate = useNavigate();
  const { getSetting } = useGlobalSettings();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setupLoader()
  }, []);

  const setupLoader = async ()=> {
    
    axios.interceptors.request.use((config) => {
      setLoading(true);

      // console.log('axios-rr : config : ', config);  // Log the entire config object

      // Check if the request needs authentication
      console.log('axios-rr : config : ', config.data);

      // if (config.rushita) {
        const token = getSetting("authToken");
        // console.log('axios-rr : authToken : ', token);

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      // }

      return config;
    }, (error) => {
      console.log('Request error occurred:', error);
      setLoading(false);
      return Promise.reject(error);
    });
  
    axios.interceptors.response.use((response) => {
      setLoading(false);
      return response;
    }, (error) => {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        console.log('response 401 : ',error);
        // sessionStorage.clear();
        // navigate('/signin');
      } else if (error.response && error.response.status === 404) {
        console.log('response 404 : ',error);
        if(getSetting("authToken") == ''){
          sessionStorage.clear();
          // navigate('/signin');
        }
      } else {
        console.log('Other error occurred:', error);
      }
      return Promise.reject(error);
    });
  }
  return loading && (
    <div className="loader-overlay">
      <div className="loader-center">
        <img src={loader} alt="Loading..." />
      </div>
    </div>
  );
};

export default Loader;
