import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TinyMCE from "../../TinyMCE.js";
import axios from "axios";
import { API_URL } from "../../../utils/config.js";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";

const EmailTemplates = () => {
  const { t } = useTranslation();

  const {
    emailTemplate,
    emailTemplateData,
    handleEmailTemplate,
    emailOnchange,
    token,
  } = useUserDataContext();

  useEffect(() => {
    getShortcodes();
    emailTemplate();
  }, []);

  const getShortcodes = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}etps`,
    };
    try {
      const response = await axios(validateconfig);
      if (response.data.success === true) {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingEmailTemplate">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseEmailTemplate"
              aria-expanded="false"
              aria-controls="flush-collapseEmailTemplate"
            >
              <strong>{t("Email Templates")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseEmailTemplate"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingEmailTemplate"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Choose Template")}
                </label>
                <select className="form-control" onChange={handleEmailTemplate}>
                  <option value="0">{t("Select an Option")}</option>
                    { emailTemplateData.length > 0 &&
                        emailTemplateData.map((item) => (
                            <option key={item.id} value={item.id}>
                            {item.template_name}
                            </option>
                        ))
                    }
                </select>
              </div>
              {emailOnchange && (
                <div className="form-group">
                  <label className="formbold-form-label">
                    {t("Edit Template")}
                  </label>
                  <TinyMCE />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplates;
