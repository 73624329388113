// IconDatePicker.js

import React, {useContext} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { SettingsContext } from '../../../contextApi/SettingsContext.js';

const IconDatePicker = ({
    selectedDate,
    onChange,
    minDate = new Date(),
}) => {

    const { settings } = useContext(SettingsContext);

    return (
        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
            <DatePicker
                selected={selectedDate != null ? new Date(selectedDate) : null}
                onChange={onChange}
                placeholderText="Select a date"
                dateFormat={settings.date_format}
                className="input-datepicker"
                minDate={minDate}
            />
            <FaCalendarAlt
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    color: "#888",
                }}
            />
        </div>
    );
};

export default IconDatePicker;