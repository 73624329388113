import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { CurrencySelectBox } from "../../form/currency-box/CurrencySelectBox.js";
import SelectBox from "../../form/select-box/SelectBox.js";
import { API_URL } from "../../../utils/config.js";
import axios from "axios";
import { SettingsContext } from '../../../contextApi/SettingsContext.js';

const BusinessProfileForm = ({ formData, handleChange, 
                                        formSubmit, setIsOpen,
                                        initial,
                                        error,
                                        allRegions,
                                        allTimeZones,
                                        dateFormats,
                                        currencyOptions,
                                        handleCurrencyChange,
                                        setBrandLogo, 
                                        brandLogo, token }) => {

  const { settings } = useContext(SettingsContext);
  const [logoError, setLogoError] = useState("");
  const uploadBusinessLogo = async (e) => {

      const config = {
        method: "POST",
        url: `${API_URL}business-settings/brand-logo`,
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {"business_logo" : e.target.files[0]},
      };
      await axios(config)
        .then((response) => {
          setBrandLogo(response.data.data.business_logo);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.success === false) {
            setLogoError(error.response.data.data);
          }
        });
    
  }

  return (
    <div className="mypreference-modal">
      <div className="close-h">
        <h4>Edit Business Profile</h4>
        <button className="closeModal" onClick={() => setIsOpen(false)}>X</button>
      </div>
      <form name="studentProfile" onSubmit={formSubmit}>
        <div className="row d-flex">
          <div className="col-xl-4 col-xxl-4">
            <div className="formbold-input-flex justify-content-center">
              <div className="student-profile-view">
                <label htmlFor="photo" className="formbold-form-label">Business Logo <span>Optional</span></label>
                <div className="initials py-3">
                  <div className="image-user">
                    {brandLogo ? (
                      <img src={brandLogo} alt="" />
                    ) : (
                      <h2>{initial && initial.toUpperCase()}</h2>
                    )}
                  </div>
                </div>
                <div className="text-center pb-2">
                  <small className="input-error-message">
                    {error?.logo?.length ? error.logo[0] : <></>}
                  </small>
                </div>
                <input type="file" name="logo" className="form-control b-none" onChange={uploadBusinessLogo} style={{ height: "auto" }}/>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-xxl-8">
            <div className="formbold-form-step-1 active">
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="business_name" className="formbold-form-label">Business Name</label>
                  <input type="text" name="business_name" className="form-control" value={formData.business_name} onChange={handleChange} required />
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="phone_number" className="formbold-form-label">Phone Number</label>
                  <input type="text" name="phone_number" className="form-control" value={formData.phone_number} onChange={handleChange} />
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="business_address" className="formbold-form-label">Business Address</label>
                  <textarea name="business_address" className="form-control" value={formData.business_address} onChange={handleChange} />
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="region" className="formbold-form-label">Region</label>
                  <select name="region" className="form-control" value={formData.region} onChange={handleChange}>
                    <option value="" disabled>Select Region</option>
                    {allRegions.map((region) => {
                      return (
                        <option data-label={region.region_label} key={region.id} value={region.id} selected={region.id == formData.region}>
                          {region.region_label}
                        </option>
                      );                    
                    })}
                  </select>
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="timeZone" className="formbold-form-label">Timezone</label>
                  <select name="timeZone" className="form-control" value={formData.timeZone} onChange={handleChange}>
                    <option value="" disabled>Select Time Zone</option>
                    {allTimeZones.map((timezone) => {
                        return (
                          <option data-label={timezone.offset} key={timezone.id} value={timezone.id} selected={timezone.id == formData.timeZone}>
                            {timezone.offset}
                          </option>
                        );
                    })}
                  </select>
                </div>
              </div>

              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="dateFormat" className="formbold-form-label">
                    Date Format
                  </label>
                  <select
                    name="date_format"
                    className="form-control"
                    value={formData.date_format}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Date Format
                    </option>
                    {dateFormats &&
                      dateFormats.map((format, index) => (
                        <option key={index} value={format} selected={format == settings.date_format}>
                          {format}
                        </option>
                      ))}
                  </select>
                </div>
              </div>


              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="currency" className="formbold-form-label">Currency</label>
                  <CurrencySelectBox
                    options={currencyOptions}
                    label="Currency"
                    onChangeSelect={handleCurrencyChange}
                    selectValue={currencyOptions.find(option => option.value === formData.currency)}
                    error={error.currency}
                    name="currency"
                    />
                
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <button type="submit" className="formbold-btn">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BusinessProfileForm;
