// src/hooks/useGlobalSettings.js

import { useContext } from "react";
import { SettingsContext } from '../contextApi/SettingsContext.js';

const useGlobalSettings = () => {
  const { settings } = useContext(SettingsContext);

  const getSetting = (key, defaultValue = null) => {
    if (key in settings) {
      return settings[key];
    }

    const value = sessionStorage.getItem(key);
    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value; // If it's not JSON, return the plain string
      }
    }

    return defaultValue; // Return the default value if key not found
  };

  return { getSetting };
};

export default useGlobalSettings;
