import React, { useState, useEffect } from "react";
import MiniSidebar from "../sidebar/MiniSidebar.js";
import Sidebar from "../sidebar/Sidebar.js";
import TopBar from "../sidebar/TopBar.js";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import students from "./assets/images/students.svg";
import { Link } from "react-router-dom";
import FetchStudentGroupDatatable from "./students/FetchStudentGroupDatatable.js";
import Loader from "../Loader.js";
import LanguageOption from "../LanguageOption.js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { API_URL } from "../../utils/config.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FetchCourseDatatable from "./students/FetchCourseDataTable.js";
import FetchModuleDatatable from "./students/FetchModuleDataTable.js";
import DeleteModal from "./courseModule/DeleteModal.js";
import Select from "react-select";
import FetchLessonDataTable from "./students/FetchLessonDataTable.js";

const Course = () => {
  const { isDarkMode, sidebarToggle, loading, token,courseData, fetchCourseData, fetchModuleData, moduleData, fetchLessonData, lessonData } = useUserDataContext();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [course_name, set_course_name] = useState("");
  const [course_description, set_course_description] = useState("");
  const[module_name, set_module_name] = useState("");
  const[module_description, set_module_description] = useState("");
  const [selectedCourses, setSelectedCourses] = useState("");
  const [coursesList, setCoursesList] = useState("");
  const [lesson_name, set_lesson_name] = useState("");
  const [lesson_description, set_lesson_description] = useState("");
  const [selectedModules, setSelectedModules] = useState("");
  const [modulesList, setModuleList] = useState("");
  const [error, setError] = useState({});
  const [showDeleteCourse, setShowDeleteCourse] = useState(false);
  const [showDeleteModule, setShowDeleteModule] = useState(false);
  const[showDeleteLesson, setShowDeleteLesson] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    course_name: "",
    course_description: "",
    course_id: "",
    module_name: "",
    module_description: "",
    module_id: "",
    lesson_name: "",
    lesson_description: "",
  });
  
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: isDarkMode ? "#363636" : "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDarkMode ? "#363636" : "white",
        color: isDarkMode ? "#FFF" : "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  useEffect(() => {
    fetchCourseData();
    fetchModuleData();
    fetchLessonData();
  }, []);
  
  useEffect(() => {
    if (courseData) {
      const transformData = (data) => {
        const courses = data.map((e) => ({
          value: e.id,
          label: e.course_name,
        }));
        setCoursesList(courses);
      };
      transformData(courseData);
    }
  }, [courseData]);

  useEffect(()=>{
    if(moduleData){
      const transformLessonData = (dataLesson) => {
        const lessons = dataLesson.map((e) => ({
          value: e.id,
          label: e.module_name,
        }));
        setModuleList(lessons);
      };
      transformLessonData(moduleData);
    }

  },[moduleData]);

  const addStudentGroupStyles = {
    content: {
      width: "45%",
      height: "90%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "#6c5a5669",
    },
  };

  const customStyles = {
    content: {
      width: "45%",
      minHeight: "35%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "#6c5a5669",
      zIndex: 9999, // Ensure the modal overlay has a higher z-index
    },
  };

  function openModal(e) {
    setIsOpen(e);
  }

  function closeModal(e) {
    setIsOpen(e);
    setError('');
    set_course_name("");
    set_course_description("");
    set_module_name("");
    set_module_description("");
    setSelectedCourses();
    set_lesson_name("");
    set_lesson_description("");
    setSelectedModules();
    setIsEditForm(false);
    setEditRow('');
    setDeleteRow('');
  }

    const saveCourse = async (e) => {
        e.preventDefault();
        formData["course_name"] = course_name;
        formData["course_description"] = course_description;
        let config = {};
        if (isEditForm) {
            // Edit Course
            config = {
                method: "PATCH",
                url: `${API_URL}course/${editRow}/update`,
                data: formData,
            };
        } else {
            // Create New Course
            config = {
                method: "POST",
                url: `${API_URL}create-course`,
                data: formData,
            };
        }
        await axios(config)
            .then((response) => {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                set_course_name("");
                set_course_description("");
                fetchCourseData(); // Refresh data
                setIsEditForm(false);
                setEditRow(''); // Clear editRow after save
                closeModal();
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.data?.success === false) {
                    setError(error.response.data.data);
                }
            });
    }


  const handleEditCourse = (row) => {
    console.log("Edit clicked for id:", row);
    openModal("addCourse");
    set_course_name(row.course_name)
    set_course_description(row.course_description);
    setIsEditForm(true);
    setEditRow(row.id);
  };

  const handleDeleteCourse =  (id) => {
    setShowDeleteCourse(true);
    setSelectedCourseId(id);
  };

  const handleDeleteModule =  (id) => {
    setShowDeleteModule(true);
    setSelectedModuleId(id);
  };

  const deleteCourse = async (e) => {
    e.preventDefault();
    console.log("Delete clicked for id:", selectedCourseId);
    const validateconfig = {
        method: "DELETE",
        url: `${API_URL}course/${selectedCourseId}/delete`,
    };
    await axios(validateconfig)
    .then((response) => {
      if (response.data.success === true) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchCourseData();
        setShowDeleteCourse(false);
        setSelectedCourseId(null); // Clear the selected ID after deletion
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const saveModule = async(e) =>{
    e.preventDefault();
        console.log("selected courses---------------", selectedCourses);
        
        formData["course_id"] = selectedCourses.value;
        formData["module_name"] = module_name;
        formData["module_description"] = module_description;
        let config = {};
        if (isEditForm) {
            // Edit Module
            config = {
                method: "PATCH",
                url: `${API_URL}module/${editRow}/update`,
                data: formData,
            };
        } else {
            // Create New Module
            config = {
                method: "POST",
                url: `${API_URL}create-module`,
                data: formData,
            };
        }
        await axios(config)
            .then((response) => {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                set_module_name("");
                set_module_description("");
                setSelectedCourses("");
                fetchModuleData(); // Refresh data
                setIsEditForm(false);
                setEditRow(''); // Clear editRow after save
                closeModal();
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.data?.success === false) {
                    setError(error.response.data.data);
                }
            });

  }

  const handleEditModule = (row) => {
    if (coursesList && coursesList.length > 0) {     
      const coureseData = coursesList.filter((f) => f.value == row.course_id);
      const selectedCourseData = coureseData.map((e) => {
        return { label: e.label, value: e.value };
      });
      setSelectedCourses(...selectedCourseData);
    }
    openModal("addModule");
    set_module_name(row.module_name);
    set_module_description(row.module_description);
    setIsEditForm(true);
    setEditRow(row.id);
  };

  const deleteModule = async (e) => {
    e.preventDefault();
    const validateconfig = {
        method: "DELETE",
        url: `${API_URL}module/${selectedModuleId}/delete`,
    };
    await axios(validateconfig)
    .then((response) => {
      if (response.data.success === true) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchModuleData();
        setShowDeleteModule(false);
        setSelectedModuleId(null); // Clear the selected ID after deletion
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const saveLesson = async (e) => {
    e.preventDefault();
    console.log("selected modules Nil--------", selectedModules);
    
    // Preparing formData for lesson
    formData["module_id"] = selectedModules.value;
    formData["lesson_name"] = lesson_name;
    formData["lesson_description"] = lesson_description;

    let config = {};
    if (isEditForm) {
      // Edit Lesson
      config = {
        method: "PATCH",
        url: `${API_URL}lesson/${editRow}/update`,
        data: formData,
      };
    } else {
      // Create New Lesson
      config = {
        method: "POST",
        url: `${API_URL}create-lesson`,
        data: formData,
      };
    }

    await axios(config)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSelectedModules("");
        set_lesson_name("");
        set_lesson_description("");
        fetchLessonData(); // Refresh data
        setIsEditForm(false);
        setEditRow(''); // Clear editRow after save
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.success === false) {
          setError(error.response.data.data);
        }
      });
  };

  const handleEditLesson = (row) => {
    if (modulesList && modulesList.length > 0) {
      const moduleData = modulesList.filter((f) => f.value === row.module_id);
      const selectedModuleData = moduleData.map((e) => {
        return { label: e.label, value: e.value };
      });
      setSelectedModules(...selectedModuleData);
    }
    openModal("addLesson");
    set_lesson_name(row.lesson_name);
    set_lesson_description(row.lesson_description);
    setIsEditForm(true);
    setEditRow(row.id);
  };

  const handleDeleteLesson =  (id) => {
    setShowDeleteLesson(true);
    setSelectedLessonId(id);
  };

  const deleteLesson = async(e) => {
    e.preventDefault();
    const validateconfig = {
        method: "DELETE",
        url: `${API_URL}lesson/${selectedLessonId}/delete`,
    };
    await axios(validateconfig)
    .then((response) => {
      if (response.data.success === true) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchLessonData();
        setShowDeleteLesson(false);
        setSelectedLessonId(null); // Clear the selected ID after deletion
      }
    })
   .catch((error) => {
    console.log(error);
    
   })

  }

  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main">
        <TopBar />
        <ReactModal
          isOpen={modalIsOpen === "addCourse"}
          onRequestClose={closeModal}
          style={addStudentGroupStyles}
          contentLabel="Course Modal"
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>{isEditForm ? "Edit Course" : "Add Course"}</strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br />
            <form name="courseForm">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="courseName" className="formbold-form-label">
                          Course Name
                        </label>
                        <div>
                          <input
                            type="text"
                            name="course_name"
                            className="form-control"
                            value={course_name}
                            onChange={(e) => set_course_name(e.target.value)}
                          />
                        </div>
                        <div className="pb-2">
                          <small className="input-error-message">
                            {error?.course_name?.length ? error.course_name[0] : <></>}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="courseDescription" className="formbold-form-label">
                          Course Description
                        </label>
                        <div>
                          <textarea
                            name="course_description"
                            className="form-control"
                            value={course_description}
                            onChange={(e) => set_course_description(e.target.value)}
                          />
                        </div>
                        <div className="pb-2">
                          <small className="input-error-message">
                            {error?.course_description?.length ? error.course_description[0] : <></>}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <button className="cancel" onClick={closeModal}>
                      Cancel
                    </button>
                    <button
                      className="formbold-btn"
                      onClick={(e) => saveCourse(e)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={modalIsOpen === "addModule"}
          onRequestClose={closeModal}
          style={addStudentGroupStyles}
          contentLabel="Module Modal"
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>{isEditForm ? "Edit Module" : "Add Module"}</strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br />
            <form name="moduleForm">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    {/* Courses dropdown ans Module */}
                    <div className="formbold-input-flex">
                      <div>
                        <label htmlFor="course_name" className="formbold-form-label">
                          Courses
                        </label>
                        <div>
                          <Select
                            value={selectedCourses}
                            onChange={(e) => setSelectedCourses(e)}
                            options={coursesList}
                            styles={colourStyles}
                          />
                        </div>
                      </div>
                      
                      <div>
                        <label htmlFor="module_name" className="formbold-form-label">
                          Module Name
                        </label>
                        <input
                          type="text"
                          name="module_name"
                          className="form-control"
                          value={module_name}
                          onChange={(e) => set_module_name(e.target.value)}
                          required
                        />
                        <small className="input-error-message">
                          {error?.module_name?.length ? error.module_name : <></>}
                        </small>
                      </div>
                    </div>
                    {/* Module Description Field */}
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="module_description" className="formbold-form-label">
                          Module Description
                        </label>
                        <textarea
                          name="module_description"
                          className="form-control"
                          value={module_description}
                          onChange={(e) => set_module_description(e.target.value)}
                          required
                        />
                        <small className="input-error-message">
                          {error?.module_description?.length ? error.module_description : <></>}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {/* Buttons */}
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <button className="cancel" onClick={closeModal}>
                      Cancel
                    </button>
                    <button
                      className="formbold-btn"
                      onClick={(e) => saveModule(e)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={modalIsOpen === "addLesson"}
          onRequestClose={closeModal}
          style={addStudentGroupStyles}
          contentLabel="Lesson Modal"
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>{isEditForm ? "Edit Lesson" : "Add Lesson"}</strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br />
            <form name="lessonForm">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    {/* Modules dropdown and Lesson Name */}
                    <div className="formbold-input-flex">
                      <div>
                        <label htmlFor="module_name" className="formbold-form-label">
                          Modules
                        </label>
                        <div>
                          <Select
                            value={selectedModules}
                            onChange={(e) => setSelectedModules(e)}
                            options={modulesList}
                            styles={colourStyles}
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="lesson_name" className="formbold-form-label">
                          Lesson Name
                        </label>
                        <input
                          type="text"
                          name="lesson_name"
                          className="form-control"
                          value={lesson_name}
                          onChange={(e) => set_lesson_name(e.target.value)}
                          required
                        />
                        <small className="input-error-message">
                          {error?.lesson_name?.length ? error.lesson_name : <></>}
                        </small>
                      </div>
                    </div>
                    {/* Lesson Description Field */}
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="lesson_description" className="formbold-form-label">
                          Lesson Description
                        </label>
                        <textarea
                          name="lesson_description"
                          className="form-control"
                          value={lesson_description}
                          onChange={(e) => set_lesson_description(e.target.value)}
                          required
                        />
                        <small className="input-error-message">
                          {error?.lesson_description?.length ? error.lesson_description : <></>}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {/* Buttons */}
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <button className="cancel" onClick={closeModal}>
                      Cancel
                    </button>
                    <button
                      className="formbold-btn"
                      onClick={(e) => saveLesson(e)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>


        <ReactModal
          isOpen={showDeleteCourse}
          onRequestClose={() => setShowDeleteCourse(false)}
          style={customStyles}
          contentLabel="Delete Course Modal"
        >
          <div className="calendar-modal">
            <div
              className="close-h"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <button
                className="closeModal"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "25px",
                  color: "red",
                }}
                onClick={()=>setShowDeleteCourse(false)}
              >
                X
              </button>
            </div>
            <div className="calendar-date-time">
              <h3 className="fw-bold">Confirm Delete</h3>
              <div className="formbold-input-flex">
                <p>
                Are you sure you want to delete this course ?
                </p>
              </div>
              <hr />
              <div className="formbold-form-btn-wrapper">
                <div className="btn-end">
                  <button
                    onClick={() => setShowDeleteCourse(false)}
                    className="cancel"
                  >
                    No
                  </button>
                  <button
                    onClick={(e) => deleteCourse(e)}
                    className="formbold-btn"
                    style={{backgroundColor:'red'}}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={showDeleteModule} 
          onRequestClose={() => setShowDeleteModule(false)}
          style={customStyles}
          contentLabel="Delete Module Modal"
        >
          <div className="calendar-modal">
            <div
              className="close-h"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <button
                className="closeModal"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "25px",
                  color: "red",
                }}
                onClick={() => setShowDeleteModule(false)}
              >
                X
              </button>
            </div>
            <div className="calendar-date-time">
              <h3 className="fw-bold">Confirm Delete</h3>
              <div className="formbold-input-flex">
                <p>Are you sure you want to delete this module?</p>
              </div>
              <hr />
              <div className="formbold-form-btn-wrapper">
                <div className="btn-end">
                  <button
                    onClick={() => setShowDeleteModule(false)}
                    className="cancel"
                  >
                    No
                  </button>
                  <button
                    onClick={(e) => deleteModule(e)} // Calls deleteModule function
                    className="formbold-btn"
                    style={{ backgroundColor: 'red' }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={showDeleteLesson}
          onRequestClose={() => setShowDeleteLesson(false)}
          style={customStyles}
          contentLabel="Delete Lesson Modal"
        >
          <div className="calendar-modal">
            <div
              className="close-h"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <button
                className="closeModal"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "25px",
                  color: "red",
                }}
                onClick={() => setShowDeleteLesson(false)}
              >
                X
              </button>
            </div>
            <div className="calendar-date-time">
              <h3 className="fw-bold">Confirm Delete</h3>
              <div className="formbold-input-flex">
                <p>Are you sure you want to delete this lesson?</p>
              </div>
              <hr />
              <div className="formbold-form-btn-wrapper">
                <div className="btn-end">
                  <button
                    onClick={() => setShowDeleteLesson(false)}
                    className="cancel"
                  >
                    No
                  </button>
                  <button
                    onClick={(e) => deleteLesson(e)} // Calls deleteLesson function
                    className="formbold-btn"
                    style={{ backgroundColor: 'red' }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>

        <main className="content student">
        <ToastContainer />
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-xl-12 col-xxl-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="course-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#course"
                      type="button"
                      role="tab"
                      aria-controls="course"
                      aria-selected="true"
                    >
                      {t("Courses")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="module-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#module"
                      type="button"
                      role="tab"
                      aria-controls="module"
                      aria-selected="false"
                    >
                      {t("Modules")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="lesson-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#lesson"
                      type="button"
                      role="tab"
                      aria-controls="lesson"
                      aria-selected="false"
                    >
                      {t("Lessons")}
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="course"
                    role="tabpanel"
                    aria-labelledby="course-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                        <div className="card flex-fill w-100">
                        <div className="card-header">
                            <div className="dropdown addnew">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              <a
                                className="btn dropdown"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                                onClick={(e) => openModal("addCourse")}
                              >
                                {t("Add Course")}
                              </a>
                            </div>
                          </div>

                          <div className="card-body d-flex">
                            <div className="align-self-center w-100">
                            <FetchCourseDatatable 
                                onEdit={handleEditCourse} 
                                onDelete={handleDeleteCourse} 
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="module"
                    role="tabpanel"
                    aria-labelledby="module-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                        <div className="card flex-fill w-100">
                          <div className="card-header">
                            <div className="dropdown addnew">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              <a
                                className="btn dropdown"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                                onClick={(e) => openModal("addModule")}
                              >
                                {t("Add Module")}
                              </a>
                            </div>
                          </div>
                          
                          <div className="card-body d-flex">
                            <div className="align-self-center w-100">
                              <FetchModuleDatatable
                                onEdit={handleEditModule} 
                                onDelete={handleDeleteModule} 
                              />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="lesson"
                    role="tabpanel"
                    aria-labelledby="lesson-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                        <div className="card flex-fill w-100">
                          <div className="card-header">
                            <div className="dropdown addnew">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              <a
                                className="btn dropdown"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                                onClick={(e) => openModal("addLesson")}
                              >
                                {t("Add Lesson")}
                              </a>
                            </div>
                          </div>
                          
                          <div className="card-body d-flex">
                            <div className="align-self-center w-100">
                              <FetchLessonDataTable
                                onEdit={handleEditLesson} 
                                onDelete={handleDeleteLesson} 
                              />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Course;
