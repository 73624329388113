import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import study from "./assets/images/study.png";
import createAccount from "./assets/images/create-account.png";
import createInvoice from "./assets/images/create-invoice.png";
import addStudent from "./assets/images/add-student.png";
import scheduleEvent from "./assets/images/schedule-event.png";
import youGotIt from "./assets/images/got-it.png";
import eventIcon from "./assets/images/event.svg";
import paymentIcon from "./assets/images/payment.svg";
import projectIcon from "./assets/images/project.svg";
import activeIcon from "./assets/images/active-student.svg";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import LanguageOption from "../LanguageOption.js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { SettingsContext } from '../../contextApi/SettingsContext.js';
import UpcomingEvents from "./dashboard/UpcomingEvents.js"; // Adjust the path accordingly
import CurrencyService from "../../services/currencyService.js";
import './assets/css/style.css';

const BusinessDashboard = ({ dashboardData, accountType }) => {
  const { userData } = useUserDataContext();
  const { t } = useTranslation();
  const { settings } = useContext(SettingsContext);
  const selectedCurrency = CurrencyService.getCurrency();
  const getSetting = (key) => {
    return settings[key] || JSON.parse(sessionStorage.getItem(key));
  };
  return (
    <div className="container-fluid p-0">
      <div className="accordion pb-1" id="dashboardAccordiontop" style={{ backgroundColor: '#f7f5ed', border: 'none' }}>
        <div className="" style={{ border: 'none' }}>
          <h2 className="accordion-header" id="headingOne" style={{ backgroundColor: '#f7f5ed', borderBottom: '1px solid #dcdcdc' }}>
            <button
              className="accordion-button collapsed business-dashboard"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOnetop"
              aria-expanded="false"
              aria-controls="collapseOnetop"
              style={{ backgroundColor: '#f7f5ed', border: 'none', paddingBottom: 0 }}
            >
              <h1 className="h3 mb-3">
                <strong>{t("dashboard.welcomeMsg")} {userData?.first_name}!</strong>
              </h1>
            </button>
          </h2>
          <div
            id="collapseOnetop"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#dashboardAccordiontop"
          >
            <div className="row all-step">
              <div className="col-xl-12 col-xxl-12 steps">
                <div className="row">
                  <div className="col-auto">
                    <div className="stat text-primary icon1">
                      <img src={createAccount} alt="create-account-icon" />
                    </div>
                  </div>
                  <div className="col mt-0">
                    <h5 className="card-title">{t("dashboard.createAccount")}</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-auto">
                    <div className="stat text-primary icon2">
                      <Link
                        // className="dropdown-item"
                        to={"/students/add"}
                      >

                        <img src={addStudent} alt="add-student-icon" style={{ left: 22 }} />
                      </Link>
                    </div>
                  </div>
                  <div className="col mt-0">
                    <h5 className="card-title">{t("dashboard.addStudent")}</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-auto">
                    <div className="stat text-primary icon3">
                      <Link
                        className="dropdown-item"
                        to={"/calendar"}
                      >
                        <img src={scheduleEvent} alt="schedule-event-icon" style={{ left: 22 }} />
                      </Link>
                    </div>
                  </div>
                  <div className="col mt-0">
                    <h5 className="card-title">{t("dashboard.scheduleEvent")}</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-auto">
                    <div className="stat text-primary icon4">
                      <Link to="/families-and-invoices/invoice/1" >
                        <img src={createInvoice} alt="create-invoice-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="col mt-0">
                    <h5 className="card-title">{t("dashboard.createInvoice")}</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-auto">
                    <div className="stat text-primary icon5">
                      <img src={youGotIt} alt="you've got it" />
                    </div>
                  </div>
                  <div className="col mt-0">
                    <h5 className="card-title">{t("dashboard.youGotIt")}</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-4 d-flex"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion pb-1" id="dashboardAccordion" style={{ backgroundColor: '#f7f5ed', border: 'none' }}>
        <div className="accordion-item" style={{ border: 'none' }}>
          <h2 className="accordion-header" id="headingOne" style={{ backgroundColor: '#f7f5ed', borderBottom: '1px solid #dcdcdc' }}>
            <button
              className="accordion-button collapsed business-dashboard"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              style={{ backgroundColor: '#f7f5ed', border: 'none' }}
            >
              <h1 className="h3 mb-0">
                <strong>{t("dashboard.category")}</strong>
              </h1>
            </button>

          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#dashboardAccordion"
          >
            <div className="custom-accordion-body">
              <div className="col-xl-12 col-xxl-12 d-flex">
                <div className="w-100">
                  <div className="row">
                    <div className="col-sm-6 pr-2">
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-primary eventIcon">
                                <img src={eventIcon} className="eventImg" />
                              </div>
                              <h5 className="card-title">
                                {t("dashboard.EventLeftThisWeek")}
                              </h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashboardData?.eventLeftThisWeekCount ? dashboardData?.eventLeftThisWeekCount : "0"}</h1>
                          
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-primary projectIcon">
                                <img src={projectIcon} />
                              </div>
                              <h5 className="card-title">
                                {t("dashboard.projectedRevenueThisMonth")}
                              </h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">
                            {dashboardData?.ProjectedRevenueThisMonth &&
                              t(`${dashboardData.ProjectedRevenueThisMonth}`)
                            }
                          </h1>
                          {/* <div className="mb-0">
                      <span className="text-success">
                        {" "}
                        <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                        5.25%{" "}
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-dark paymentIcon font-weight-bold pt-2">
                                {/* <img src={paymentIcon} className="paymentImg" /> */}
                                <h5> {getSetting('currency_symbol')} </h5>
                              </div>
                              <h5 className="card-title">
                                {t("dashboard.paymentRecievedThisMonth")}
                              </h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashboardData?.PaymentRecievedThisMonth ? dashboardData?.PaymentRecievedThisMonth : "0"}</h1>
                          {/* <div className="mb-0">
                      <span className="text-success">
                        {" "}
                        <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                        6.65%{" "}
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-primary activeIcon">
                                <img src={activeIcon} />
                              </div>
                              <h5 className="card-title">{t("dashboard.activeStudents")}</h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashboardData?.activeStudentsCount ? dashboardData?.activeStudentsCount : "0"}</h1>
                          {/* <div className="mb-0">
                      <span className="text-danger">
                        {" "}
                        <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                        -2.25%{" "}
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upcoming Events Component */}
      <div className="row mt-2">
        <div className="col-12">
          <UpcomingEvents type={accountType} />
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboard;
