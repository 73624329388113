import React, { useRef, useEffect, useState } from "react";
import MiniSidebar from "../sidebar/MiniSidebar.js";
import Sidebar from "../sidebar/Sidebar.js";
import TopBar from "../sidebar/TopBar.js";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";
import SideSelectionBox from "./SideSelectionBox.js";
import { useHistory, useNavigate } from 'react-router-dom';
import {
  getEventDetailsById,
  getAttendanceTypes,
  updateTakeAttendanceEvents,
  getNotes,
} from "../../services/calenderService.js";
import { useParams, useLocation } from "react-router-dom";
import AttendancePage from "./AttendancePage.js";
import LessonDetails from "./LessonDetails.js";
import attendance from  "../../../src/components/users/students/assets/images/attendance.svg"
import { Button } from "@mui/material";
import ChecklistRtl from '@mui/icons-material/ChecklistRtl';

const Attendance = () => {
  const param = useParams();
  const editorRef = useRef(null);
  const [attendees_info, set_attendees_info] = useState([]);
  const [start_date, set_start_date] = useState("");
  const [start_time, set_start_time] = useState("");
  const [end_time, set_end_time] = useState("");
  const [require_makeup_credits, set_require_makeup_credits] = useState(0);
  const [attendees_ids, set_attendees_ids] = useState([]);
  const [attendance_types, set_attendance_types] = useState([]);
  const [student_id, set_student_id] = useState("");
  const [attendance_status, set_attendance_status] = useState(1);
  const [is_late, set_is_late] = useState(false);
  const [lesson_price, set_lesson_price] = useState(0);
  const [billing_type, set_billing_type] = useState("");
  const [lesson_details, set_lesson_details] = useState("");
  const [email_tutor, set_email_tutor] = useState(false);
  const [email_student, set_email_student] = useState(false);
  const [is_paid_at_lesson, set_is_paid_at_lesson] = useState(0);
  const [event_name, set_event_name] = useState("");
  const [showCards, setShowCards] = useState(false);
  const [showLessonDetails, setShowLessonDetails] = useState(false);
  const [showAttendaceForm, setShowAttendaceForm] = useState(false);
  const [student_notes, setStudentNotes] = useState(" ");
  const [parent_notes, setParentNotes] = useState(" ");
  const [private_notes, setPrivateNotes] = useState(" ");
  const buttonRef = useRef(null);
  const { sidebarToggle, token } = useUserDataContext();
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0); // To track the current student
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [attendancePending, setAttendancePending] = useState(false); // By default, set to false
  const [lessionVal, setlessionVal] = useState(0);
  const [paid_amount, set_paid_amount] = useState(0);
  const [selectedCourses, setSelectedCourses] = useState("");
  const [selectedModules, setSelectedModules] = useState("");
  const [selectedLessons, setSelectedLessons] = useState("");

  const navigate = useNavigate();

  // const location = useLocation();
  // const { id } = location.state || {}; // Extract the id from state

  const getAttendanceTypesHandler = async () => {
    const result = await getAttendanceTypes();
    set_attendance_types(result.data);
  };

  const handleTakeAttendanceClick = () => {
    setShowAttendaceForm(true);
  };

  const handleCancelClick = () => {
    setShowAttendaceForm(false);
    set_attendees_ids([]);
  };

  const takeAttendanceHandler = async () => {
    const result = await getEventDetailsById(param.id);
    console.log("result is from takeAttendanceHandler------------", result);
    const attendees_info = result?.data?.attendees_info;
    console.log("DATA=>", result?.data);
    set_event_name(result?.data?.event_name);
    set_attendees_info(attendees_info);
    set_start_date(result?.data?.start_date);
    set_start_time(result?.data?.start_time);
    set_end_time(result?.data?.end_time);
    set_require_makeup_credits(result?.data?.require_makeup_credits);
    // Set the attendancePending flag from the API response
    setAttendancePending(result?.data?.attendance_pending);
  };

  const attendanceSubmitHandler = async (shouldNavigate = true) => {
    if (attendees_ids.length > 0) {
      let dataObj = {
        student_ids: JSON.stringify(attendees_ids),
        occurrence_id: param.id,
        attendance_status: attendance_status,
        is_late: is_late ? 1 : 0,
        lesson_price: lesson_price,
        paid_amount:paid_amount,
        email_tutor: email_tutor ? 1 : 0,
        email_student: email_student ? 1 : 0,
        is_paid_at_lesson: is_paid_at_lesson ? 1 : 0,
        student_notes:student_notes,
        parent_notes:parent_notes,
        private_notes:private_notes,
        selectedCourses:selectedCourses,
        selectedModules:selectedModules,
        selectedLessons:selectedLessons
      };
      if (editorRef.current) {
        dataObj.lesson_details = editorRef.current.getContent();
      }
      if (billing_type == "used_makeup_credits") {
        dataObj.given_notice = 0;
        dataObj.give_makeup_credit = 0;
        dataObj.lesson_is_billable = 0;
        dataObj.used_makeup_credits = 1;
      } else if (billing_type == "lesson_is_billable") {
        dataObj.given_notice = 0;
        dataObj.give_makeup_credit = 0;
        dataObj.lesson_is_billable = 1;
        dataObj.used_makeup_credits = 0;
      } else if (billing_type == "given_notice") {
        dataObj.given_notice = 1;
        dataObj.give_makeup_credit = 0;
        dataObj.lesson_is_billable = 0;
        dataObj.used_makeup_credits = 0;
      } else if (billing_type == "not_billable") {
        dataObj.given_notice = 1;
        dataObj.give_makeup_credit = 0;
        dataObj.lesson_is_billable = 0;
        dataObj.used_makeup_credits = 0;
      } else if (billing_type == "absence_is_billable") {
        dataObj.given_notice = 0;
        dataObj.give_makeup_credit = 0;
        dataObj.lesson_is_billable = 1;
        dataObj.used_makeup_credits = 0;
      } else if (billing_type == "give_makeup_credit") {
        dataObj.given_notice = 0;
        dataObj.give_makeup_credit = 1;
        dataObj.lesson_is_billable = 0;
        dataObj.used_makeup_credits = 0;
      }
      const response = await updateTakeAttendanceEvents(dataObj);
      if (response.success) {
         // Update the state for the current student
      const updatedAttendeesInfo = [...attendees_info];
      const currentStudent = updatedAttendeesInfo[currentStudentIndex];
      
      currentStudent.attendance_status = attendance_status;
      currentStudent.attendance_taken = true;
      // Update the state with the new attendees info
      set_attendees_info(updatedAttendeesInfo);
      // navigate('/dashboard');
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        
        if (shouldNavigate) {
          // Navigate only if `shouldNavigate` is true
          takeAttendanceHandler();
        }
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      alert("please select student");
    }
  };

  const handleNextStudent = async () => {
    // Save the current student's attendance data
    await attendanceSubmitHandler(false); // Don't navigate yet
  
    // Filter out the current student ID and move to the next one
    let updatedAttendeesIds = attendees_ids.filter(
      (id) => id !== attendees_info[currentStudentIndex].id
    );
  
    const nextIndex = currentStudentIndex + 1;
    
    if (nextIndex < attendees_info.length) {
      // Move to the next student
      updatedAttendeesIds.push(attendees_info[nextIndex].id);
      setCurrentStudentIndex(nextIndex);
      set_attendees_ids(updatedAttendeesIds);
    } else {
      // If this is the last student, navigate to the dashboard
      attendanceSubmitHandler(); // This will save and navigate
      navigate('/dashboard');
    }
  };
  useEffect(() => {
    takeAttendanceHandler();
    getAttendanceTypesHandler();
  }, []);

  const fetchNotes = async () => {
    const studentId = attendees_ids.length === 1 ? attendees_ids[0] : null;
    try {
      const response = await getNotes(param.id, studentId);
      // Check if response.data is null or missing, and handle accordingly
      if (response.success && response.data) {
        // Safely update the state with data or default to empty values
        setStudentNotes(response.data.student_notes || '');
        setParentNotes(response.data.parent_notes || '');
        setPrivateNotes(response.data.private_notes || '');
        set_attendance_status(response.data.attendance_status || null);
        set_lesson_price(response.data.lesson_price || '');
        setlessionVal(response.data.lesson_price)
        set_paid_amount(response.data.paid_amount);
        set_is_paid_at_lesson(response.data.is_paid_at_lesson);
      } 
        else if (response.success && response.data === null) {
        // Handle the case where the data is null
        console.log("No notes found, initializing default values.");
        setStudentNotes('');  // Set default empty notes
        setParentNotes('');
        setPrivateNotes('');
        set_attendance_status(null);  // Attendance status to null
        set_lesson_price('');
        setlessionVal('');
        set_paid_amount('');
        set_is_paid_at_lesson('');
      } else {
        console.error("Error fetching notes:", response.message || "No data received");
      }
    } catch (error) {
      console.error("Exception occurred while fetching notes:", error);
    }
  };

  const handleTakeAttendance = () => {
    console.log("handeler from button/checkbox------------------");
    // Handle visibility of cards based on selected attendees
    if (attendees_ids.length === 0) {
      setShowCards(false);
      setIsButtonDisabled(false); // Disable
    } else if (attendees_ids.length === 1) {
      setShowCards(true);
      setShowLessonDetails(true); // Show lesson details if exactly one student is selected
      // Fetch notes for the selected student when exactly one student is selected
      fetchNotes(attendees_ids[0]);
      setIsButtonDisabled(false); 
    } else {
      setShowCards(true);
      setShowLessonDetails(true);   // show lesson details if multiple students are selected
      // Check if all students are selected
      if (attendees_ids.length === attendees_info.length) {
        setIsButtonDisabled(true); // Disable the "Save & Continue" button
      } else {
        setIsButtonDisabled(false); // Enable button if not all students are selected
      }
    }
  };

  useEffect(()=>{
    handleTakeAttendance()
  },[attendees_ids])


  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}
      <div className="main">
        <TopBar />
        <main className="content">
          <ToastContainer />
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-4">
                <SideSelectionBox
                  attendees_info={attendees_info}
                  start_date={start_date}
                  start_time={start_time}
                  end_time={end_time}
                  require_makeup_credits={require_makeup_credits}
                  attendees_ids={attendees_ids}
                  set_attendees_ids={set_attendees_ids}
                  event_name={event_name}
                  handleTakeAttendance={handleTakeAttendance}
                  buttonRef={buttonRef}
                />
              </div>
              <div className="col-md-8">
                {showAttendaceForm ? (
                <div className="container-fluid p-0 bg-color bg-color-white main-content-area">
                  <div className="upper-box-area">
                      {showCards && (
                      <div className="card">
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingOne"
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                <strong>Attendance here</strong>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              className="accordion-collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <p>Record attendance for this lesson</p>
                                <AttendancePage
                                  attendance_types={attendance_types}
                                  set_attendance_types={set_attendance_types}
                                  attendance_status={attendance_status}
                                  set_attendance_status={set_attendance_status}
                                  billing_type={billing_type}
                                  set_billing_type={set_billing_type}
                                  is_late={is_late}
                                  set_is_late={set_is_late}
                                  lesson_price={lesson_price}
                                  set_lesson_price={set_lesson_price}
                                  is_paid_at_lesson={is_paid_at_lesson}
                                  set_is_paid_at_lesson={set_is_paid_at_lesson}
                                  lessionVal={lessionVal}
                                  paid_amount={paid_amount}
                                  set_paid_amount={set_paid_amount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        )} 

                      {showLessonDetails && (
                      <div className="card">
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingTwo"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                <strong>Lesson Details (Optional)</strong>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <p>Record attendance for this lesson</p>
                                <LessonDetails
                                  editorRef={editorRef}
                                  email_student={email_student}
                                  set_email_student={set_email_student}
                                  email_tutor={email_tutor}
                                  set_email_tutor={set_email_tutor}
                                  student_notes={student_notes}
                                  setStudentNotes={setStudentNotes}
                                  parent_notes={parent_notes}
                                  setParentNotes={setParentNotes}
                                  private_notes={private_notes}
                                  setPrivateNotes={setPrivateNotes}
                                  selectedCourses={selectedCourses}
                                  setSelectedCourses={setSelectedCourses}
                                  selectedModules={selectedModules} 
                                  setSelectedModules={setSelectedModules}
                                  selectedLessons={selectedLessons}
                                  setSelectedLessons={setSelectedLessons}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                       )}

                  </div>
                  <div className="lower-box-area">
                    <div className="formbold-form-btn-wrapper">
                      
                      <div className="btn-end">
                        <button onClick={handleCancelClick} className="cancel">
                          Cancel
                        </button>
                        <button
                          onClick={() => attendanceSubmitHandler()}
                          className="formbold-btn"
                        >
                          Save
                        </button>
                        {attendancePending && (
                          <button
                          onClick={handleNextStudent}
                          className="formbold-btn"
                          disabled={isButtonDisabled}
                          style={{ display: isButtonDisabled ? "none" : "block" }} // Hide the button if `isButtonDisabled` is true
                        >
                          Save & Continue
                        </button>
                        )}
                      </div>
                      
                    </div>
                  </div>
                </div>
                ) : (
                  <div className="row">
                        <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3 d-flex flex-column vh-100 align-items-center justify-content-center">
                              <div className="w-100">
                                    <div className="py-3">
                                      <div className="chart chart-xs payroll-img">
                                        <img src={attendance} alt="payroll"></img>
                                      </div>
                                    </div>
                              </div>
                              <Button
                                ref={buttonRef}
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={handleTakeAttendanceClick}
                                disabled={!attendees_ids.length}
                                startIcon={<ChecklistRtl color="secondary" />} //primary

                              > Take Attendance
                              </Button>
                        </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Attendance;
