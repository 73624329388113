import axios from "axios";
import { API_URL } from "../utils/config";

export const getAddressTypes = async () => {
  const config ={
    method : "GET",
    url: `${API_URL}latypes`
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const createLocations = async (data) => {
  const config ={
    method : "POST",
    url: `${API_URL}create-eventloc`,
    data: data
  };
  return await axios(config).then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
};
export const updateLocations = async (data,id) => {
  const config ={
    method : "PATCH",
    url: `${API_URL}update-eventloc/`+id,
    data: data
  };
  return await axios(config).then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
};

export const deleteLocations = async (id) => {
  const config ={
    method : "DELETE",
    url: `${API_URL}delete-eventloc/`+id
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getLocationsDetails = async (id) => {
  const config ={
    method : "GET",
    url: `${API_URL}eventloc/`+id
  };
  return await axios(config).then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
};

export const getLocations = async () => {
  const config ={
    method : "GET",
    url: `${API_URL}eventloc`
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};