import React, { createContext, useState, useEffect } from 'react';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(() => {
        const initialSettings = {};
        Object.keys(sessionStorage).forEach(key => {
            // try {
            //     initialSettings[key] = JSON.parse(sessionStorage.getItem(key));
            // } catch (e) {
                initialSettings[key] = sessionStorage.getItem(key); // If it's not JSON, store it as a plain string
            // }
        });
        return initialSettings;
    });

    useEffect(() => {
        Object.keys(settings).forEach(key => {
            sessionStorage.setItem(key, settings[key]);
        });
    }, [settings]);

    return (
        <SettingsContext.Provider value={{ settings, setSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};
