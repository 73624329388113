import React, { useContext,useEffect, useState } from 'react';
import { verifyToken } from "../services/authService.js";
import { useUserDataContext } from "../contextApi/userDataContext.js";
import { AuthContext } from '../components/registerLogin/AuthContext.js';
import axios from "axios";
import { API_URL } from "../utils/config.js";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from '../contextApi/SettingsContext.js';
import useGlobalSettings from "../hooks/useGlobalSettings.js";
import { validateDomainName } from "../services/loginService.js";
import { toast } from "react-toastify";

export function useTokenStorage() {
    const { setToken, setUserData, setUserId, token } = useUserDataContext();
    const { role, setRole } = useContext(AuthContext);
    const { settings, setSettings } = useContext(SettingsContext);
    const { getSetting } = useGlobalSettings();

    const navigate = useNavigate();

    useEffect(() => {
        const userRole = getSetting("userRole");
        console.log('this is : ', token);
        if (token && token.trim() !== "") {
            fetchData(userRole, setUserData, setUserId, setSettings);
        } else {
            console.log("Token is null or blank, fetchData will not be called.");
        }        
    }, [token]);

    return function storeToken(token, domain, role=0) {

        //store token in session
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem(`${domain}`, token);
        sessionStorage.setItem("userRole", String(role));
        setToken(token);
        setRole(String(role));
        return true;
    };
}

//fetch user details
export const fetchData = (userRole, setUserData, setUserId, setSettings) => {

    var url = `${API_URL}tenant/details`;
    if(userRole == process.env.REACT_APP_STUDENT_ROLE){
        url = `${API_URL}student/profile`;
    }
    const validateconfig = {
        method: "GET",
        url: url,
    };
    axios(validateconfig)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.success === true) {
                    setUserData(response.data.data);
                    setUserId(response.data.data.id);
                    sessionStorage.setItem("user_id",response.data.data.id);
                    sessionStorage.setItem("user_name",response.data.data.first_name);
                    const dpUrl = response.data.data.business_data?.dp_url || response.data.data.dp_url;
                    sessionStorage.setItem("user_profile", dpUrl);

                    const settingsObj = {};
                    const settingData = response.data.data.business_data;
                    Object.keys(settingData).forEach(key => {
                        sessionStorage.setItem(key, JSON.stringify(settingData[key]));
                        settingsObj[key] = settingData[key];
                    });
                    setSettings(settingsObj);
                }
            }
        })
        .catch((error) => {
            console.log('Error occurred:', error);
        });
};

//checkAuth and redirect
export const checkAuthAndRedirect = async (navigate, from, expectedDomain, token) => {
    const domainFromUrl = window.location.hostname;

    if(expectedDomain == null){
        expectedDomain = `${process.env.REACT_APP_EXPECTED_DOMAIN}`;
    }

    if(from == 'Register'){
        if(expectedDomain){
            if (domainFromUrl != expectedDomain) {
                if (token && token.trim() !== '') {
                    var response = await verifyToken();
                    if (response) {
                        navigate('/dashboard');
                    }else{
                        sessionStorage.clear();
                        navigate('/signin');                    }
                }else{
                    window.location.href = `${process.env.REACT_APP_PROTOCOL}://${expectedDomain}/`;
                }
            }
        }
    }

    if(from == 'DomainName'){
        if (domainFromUrl != expectedDomain) {
            if (token && token.trim() !== '') {
                var response = await verifyToken();
                if (response) {
                    navigate('/dashboard');
                } else {
                    sessionStorage.clear();
                    navigate('/signin');
                }
            }else{
                sessionStorage.clear();
                navigate('/signin');
            }
        }
    }

    if(from == 'Signin' || from == 'ForgotPass'){
        if (domainFromUrl != expectedDomain) {
            if (token && token.trim() !== '') {

                // Redirect to dashboard if token is present
                // navigate('/dashboard');
                var response = await verifyToken();
                if (response) {
                    navigate('/dashboard');
                } else {
                    sessionStorage.clear();
                }
            }else{
                sessionStorage.clear();
                const data = {"domain": domainFromUrl};
                const response = await validateDomainName(data);
                if (response && response.success) {
                } else {
                    toast.error(response.data.domain, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    window.location.href = `${process.env.REACT_APP_PROTOCOL}://${expectedDomain}/domain-signin`;
                }
            }
        }else{
            navigate('/domain-signin');
        }
    }
}