import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";
import Loader from "../../Loader.js";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const FetchModuleDatatable = ({ onEdit, onDelete }) => {
  const [val, setVal] = useState(false);
  const { fetchModuleData, moduleData } = useUserDataContext();

  useEffect(() => {
    fetchModuleData();
  }, []);

  useEffect(() => {
    moduleData && setVal(true);
  }, [moduleData]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "module_name", headerName: "Module Name", width: 200 },
    { field: "course_name", headerName: "Course Name", width: 200 },
    { field: "module_description", headerName: "Module Description", width: 400 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div>
          <IconButton size="small" onClick={() => onEdit(params.row)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(params.row.id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const rows = val ? moduleData : [];

  return (
    <div>
      {rows && moduleData.length > 0 ? (
        <div className="py-3">
          <div className="chart chart-xs">
            <Box
              sx={{
                height: 400,
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: "15px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "700",
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[20]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </Box>
          </div>
        </div>
      ) : (
        <div className="py-3">
          <h4>
            <strong>No modules available</strong>
          </h4>
          <p style={{ textAlign: "center" }}>
            Add a module to see it here.
          </p>
        </div>
      )}
    </div>
  );
};

export default FetchModuleDatatable;
