import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './style.css';

const SideSelectionBox = ({
  attendees_info,
  start_date,
  start_time,
  end_time,
  require_makeup_credits,
  attendees_ids,
  set_attendees_ids,
  event_name,
  handleTakeAttendance,
  buttonRef
}) => {

  const location = useLocation();
  const [selectedAttendee, setSelectedAttendee] = useState(null);

  const getQueryParams = (queryString) => {
    const params = new URLSearchParams(queryString);
    return Object.fromEntries(params.entries());
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    if (queryParams.attendee && !isNaN(parseInt(queryParams.attendee))) {
      setSelectedAttendee(parseInt(queryParams.attendee));
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedAttendee) {
      studentCheckedhandler(true, selectedAttendee);
      setTimeout(() => {
        if (buttonRef && buttonRef.current) { // Check if buttonRef is defined
          buttonRef.current.click(); // Programmatically click the button
        }
      }, 100); // Adjust delay as needed
    }
  }, [selectedAttendee, buttonRef]);

  const studentCheckedhandler = (checked, id) => {
    if (checked) {
      if (attendees_ids.find((f) => f == id) == undefined) {
        set_attendees_ids([...attendees_ids, id]);
      }
    } else {
      set_attendees_ids(attendees_ids.filter((f) => f != id));
    }
  };

  const selectAllHandler = (checked) => {
    if (checked) {
      const allIds = attendees_info.map(attendee => attendee.id);
      set_attendees_ids(allIds);
    } else {
      set_attendees_ids([]);
    }
  };

  const isSelectAllChecked = () => {
    if (attendees_ids.length === attendees_info.length) {
      return true;
    } else if (attendees_ids.length === 0) {
      return false;
    } else {
      return 'indeterminate';
    }
  };


  useEffect(() => {
    const selectAllCheckbox = document.getElementById('select-all-checkbox');
    if (selectAllCheckbox) {
      selectAllCheckbox.indeterminate = isSelectAllChecked() === 'indeterminate';
    }
  }, [attendees_ids]);

  return (
    <div className="container-fluid p-0 bg-color-white">
      <div className="header-info-section">
        <div className="calendar-date-time">
          <h4>{event_name}</h4>
          <div className="current-date">
            <i className="fa fa-calendar" aria-hidden="true"></i>
            <span className="ml-4">{start_date}</span>
          </div>
          <div className="current-date">
            <i className="fa fa-clock" aria-hidden="true"></i>
            <span className="ml-4">{start_time} - {end_time}</span>
          </div>
          {require_makeup_credits == 1 && (
            <div className="text-makup-credit">
              <i className="fa fa-recycle" aria-hidden="true"></i>
              <span className="ml-4">Make-Up Credit Required</span>
            </div>
          )}
        </div>
      </div>
      <div className="header-attendees-section">
        <div className='attendees'>
            <label className="title-attendees-info">{attendees_info.length || 0} Attendees</label>
            <label className="title-attendees-id">{attendees_ids.length || 0} Selected</label>
        </div>
        
        <label className='attendees-checkbox'>
          <input
            id="select-all-checkbox"
            type="checkbox"
            checked={isSelectAllChecked() === true}
            onChange={(e) => selectAllHandler(e.target.checked)}
          />
          <span className="ml-4">Select All</span>
        </label>
        <div className="attendees-list-box">
          <ul>
            {attendees_info?.map((e) => (        
                <div className="addendees-box">
                  <div className="addendees-box-left">
                    <label>
                      <input
                        type="checkbox"
                        checked={attendees_ids.includes(e.id)}
                        onChange={(c) => studentCheckedhandler(c.target.checked, e.id)}
                      />
                      <span className="ml-4">{e.name}</span>
                    </label>
                  </div>
                <div className="addendees-box-right">
                  {e.attendance_taken && e.attendance_status == 2 && (
                    <i className="fa fa-check-circle" style={{ color: 'green' }} aria-hidden="true"></i>
                  )}
                  {e.attendance_taken && e.attendance_status != 2 && (
                    <i className="fa fa-times-circle" style={{ color: 'red' }} aria-hidden="true"></i>
                  )}
                  {e.attendance_status == 1 && "(U)"}
                  {e.attendance_status == 2 && "(P)"}
                  {e.attendance_status == 3 && "(A)"}
                  {e.attendance_status == 4 && "(TC)"}
                </div>
                </div>
              
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideSelectionBox;
