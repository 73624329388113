import React from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';

/**
 * PasswordModal Component
 * 
 * @param {object} props - Component props
 * @param {boolean} props.isOpen - Indicates whether the modal is open
 * @param {function} props.onAfterOpen - Function to call after the modal opens
 * @param {function} props.onRequestClose - Function to call to request closing the modal
 * @param {object} props.style - Custom styles for the modal
 * @param {string} props.contentLabel - Label for the modal
 * @param {function} props.handleChange - Function to handle input changes
 * @param {function} props.handleSubmit - Function to handle form submission
 * @param {object} props.error - Object containing any error messages
 */
const PasswordModal = ({
  isOpen,
  onAfterOpen,
  onRequestClose,
  style,
  contentLabel,
  handleChange,
  handleSubmit,
  error
}) => (
  <ReactModal
    isOpen={isOpen}
    onAfterOpen={onAfterOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel={contentLabel}
  >
    <div className="mypreference-modal">
      {/* Modal header */}
      <div className="close-h">
        <h4>{contentLabel}</h4>
        <button className="closeModal" onClick={onRequestClose}>
          X
        </button>
      </div>

      {/* Modal form */}
      <form name="studentProfile">
        <div className="row d-flex">
          <div className="col-xl-12 col-xxl-12">
            <div className="formbold-form-step-1 active">
              {/* Current Password Input */}
              <div className="formbold-input-flex">
                <div>
                  <label
                    htmlFor="current_password"
                    className="formbold-form-label"
                    id="current_password"
                  >
                    Current Password
                  </label>
                  <input
                    type="password"
                    name="current_password"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="pt-2">
                    {error?.length > 0 && (
                      <small className="input-error-message">
                        {error}
                      </small>
                    )}
                    {error?.current_password?.length > 0 && (
                      <small className="input-error-message">
                        {error.current_password[0]}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* New Password Input */}
              <div className="formbold-input-flex">
                <div>
                  <label
                    htmlFor="new_password"
                    className="formbold-form-label"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    name="new_password"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="pt-2">
                    {error?.new_password?.length > 0 && (
                      <small className="input-error-message">
                        {error.new_password[0]}
                      </small>
                    )}
                  </div>
                </div>

                {/* Confirm New Password Input */}
                <div>
                  <label
                    htmlFor="confirm_new_password"
                    className="formbold-form-label"
                    id="confirm_new_password"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    name="confirm_new_password"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="pt-2">
                    {error?.confirm_new_password?.length > 0 && (
                      <small className="input-error-message">
                        {error.confirm_new_password[0]}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal footer */}
        <hr />
        <div className="formbold-form-btn-wrapper">
          <div className="btn-end">
            <Link className="cancel" onClick={onRequestClose}>
              Cancel
            </Link>
            <button
              className="formbold-btn"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </ReactModal>
);

export default PasswordModal;
