import React, { useEffect, useState } from "react";
import Select from "react-select";

export const CurrencySelectBox = ({ options,label,onChangeSelect,selectValue,error,name, loadOptions }) => {

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(selectValue);
    console.log("value from use effect------",selectedOption, "select value ----------", selectValue  );
  }, [selectValue]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChangeSelect(selectedOption);
  };

  return (
    <div className="form-group last mb-3">
      <Select
        className=""
        name={name}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isMulti={false}
        isSearchable={true}
        placeholder={`Select ${label}`}
      />
      <small className="input-error-message">
        {error?.length ? error : null}
      </small>
    </div>
  );
};
