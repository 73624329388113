import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput"; 
import { bodyOpenClassName } from "react-modal/lib/components/Modal";
import { Button } from "@mui/material";
import ChecklistRtl from '@mui/icons-material/ChecklistRtl';

export const EventModal = ({
      bodyOpenClassName,
      isOpen,
      afterOpenModal,
      closeModal,style,contentLabel,
      getEventDetailsByIdHandler,
      selectedEventId,
      createCloneHandler,resetDeleteForm,openModal,userData,
      eventDuration,eventTitle,eventDesc,eventStartTime,eventEndTime,
      attendees_info,takeAttendanceHandler,tutorName, tutorId, takeAttendance}) => (
    <ReactModal
          bodyOpenClassName={bodyOpenClassName}
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={style}
          contentLabel={contentLabel}
        >
          <div className="calendar-modal">
            <div className="close-h">
              <div className="popup-icons">
                <i
                  onClick={() => getEventDetailsByIdHandler(selectedEventId)}
                  class="fa fa-pencil"
                  aria-hidden="true"
                ></i>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <i class="fa fa-commenting" aria-hidden="true"></i>
                <i
                  onClick={() => createCloneHandler(selectedEventId)}
                  class="fa fa-clone"
                  aria-hidden="true"
                ></i>
                <i
                  onClick={() => {
                    resetDeleteForm();
                    openModal("deleteEvent");
                  }}
                  class="fa fa-trash"
                  aria-hidden="true"
                ></i>
              </div>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <div className="calendar-date-time">
              <p className="event-title">{eventTitle}</p>
              {console.log('tutorName : ',tutorName)}
              {tutorName != "" && <span>Lesson with {tutorName}</span> }
              <div className="current-date p-0">
                <i class="fa fa-calendar" aria-hidden="true"></i>
                {eventDesc.start}
              </div>
              <div className="current-date p-0">
                <i class="fa fa-clock" aria-hidden="true"></i>
                {eventStartTime} - {eventEndTime} ({eventDuration} Mins)
              </div>
              <div className="list-attendees py-2">
                <label> Total Attendees: {attendees_info.length || 0}</label>
                {attendees_info?.map((e) => {
                  return (
                    <div className="d-flex justify-content-between">
                      <p>{e.name}</p>
                      <p>
                        {e.attendance_taken && e.attendance_status == 2 && (
                          <i
                            class="fa fa-check-circle"
                            style={{ color: "green" }}
                            aria-hidden="true"
                          ></i>
                        )}
                        {e.attendance_taken && e.attendance_status != 2 && (
                          <i
                            class="fa fa-times-circle"
                            style={{ color: "red" }}
                            aria-hidden="true"
                          ></i>
                        )}
                        {e.attendance_status == 1 && "(U)"}
                        {e.attendance_status == 2 && "(P)"}
                        {e.attendance_status == 3 && "(A)"}
                        {e.attendance_status == 4 && "(TC)"}
                      </p>
                    </div>
                  );
                })}
              </div>
              <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => takeAttendanceHandler(selectedEventId)}
                  disabled={!takeAttendance}
                  startIcon={<ChecklistRtl color="secondary" />} //primary

                > Take Attendance
                </Button>
            </div>
          </div>
        </ReactModal>
)