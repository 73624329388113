import { format } from 'date-fns';
import moment from "moment";

const phpToJsFormatMap = {
  'd': 'dd',
  'D': 'EEE',
  'j': 'd',
  'l': 'EEEE',
  'F': 'MMMM',
  'm': 'MM',
  'M': 'MMM',
  'n': 'M',
  'Y': 'yyyy',
  'y': 'yy',
  'H': 'HH',
  'h': 'hh',
  'i': 'mm',
  's': 'ss',
  'A': 'aa',
};

export const convertPhpToJsDateFormat = (phpDateFormat) => {
  if (!phpDateFormat || typeof phpDateFormat !== 'string') {
    return 'yyyy-MM-dd';
  }

  let jsDateFormat = phpDateFormat;

  Object.keys(phpToJsFormatMap).forEach(phpFormat => {
    const jsFormat = phpToJsFormatMap[phpFormat];
    const regExp = new RegExp(`\\b${phpFormat}\\b`, 'g');
    jsDateFormat = jsDateFormat.replace(regExp, jsFormat);
  });

  return jsDateFormat || 'yyyy-MM-dd';
};

export const displayFormattedDate = (dateValue, dateFormat = null) => {
  if (!dateValue || isNaN(new Date(dateValue).getTime())) {
    return 'Invalid Date';
  }

  try {
    const date = new Date(dateValue);

    if (dateFormat) {
      const jsDateFormat = convertPhpToJsDateFormat(dateFormat);
      return format(date, jsDateFormat);
    }

    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    return 'Invalid Date';
  }
};

export const convertDateForSentInApi = (date) =>{
  return date != null ? moment(date).format(`${process.env.REACT_APP_API_DATE_FORMAT}`) : null;
}